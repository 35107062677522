body {
  -webkit-print-color-adjust: exact !important;
}
.addinvoice {
  background-color: white;
  padding: 20px;
  /* border: 1px solid; */
  transition: all 0.5s ease;

  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
  margin-top: 74px;
  /* margin-top: -50px; */
  margin-right: 20px;
}
.addinvoice_header {
  display: flex;
  justify-content: space-between;
}
.addinvoice_headerbutton {
  display: flex;
}
.addinvoice_details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1200px) {
  .addinvoice_details {
    flex-direction: column-reverse;
  }
}
.addinvoice_clientdetails {
  /* border: 1px solid black; */
  /* margin: 5px; */
  flex: 0.4;
  /* width: 300px; */
  /* padding: 15px; */
}

.addinvoice_clientdetail {
  display: flex;
  margin: 15px 5px;
}
.addinvoice_status {
  /* padding: 8px;
  border: 3px solid red;
  font-weight: bolder !important;
  color: red;
  text-align: center;
  width: 100%; */
  font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 10px;
    border: 2px solid crimson;
    color: crimson;
    border-radius: 6px;
    align-items: center;
    text-align: center;
    width: 6rem;
}
.addinvoice_status2 {
  /* padding: 8px;
  border: 3px solid green;
  font-weight: bolder !important;
  color: green;
  width: 100%;
  text-align: center; */
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 10px;
  border: 2px solid green;
  color: green;
  border-radius: 6px;
  align-items: center;
  text-align: center;
  width: 6rem;
  /* transition: transform 0.5s ease; */
}
/* .addinvoice_status:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.addinvoice_status2:hover {
  cursor: pointer;
  transform: scale(1.2);
} */
.addinvoice_headerdatedetails {
  margin-bottom: 10px;
  flex: 0.34;
  align-items: center;
}
.viewinvoice_headerdatedetails {
  margin-bottom: 10px;
  flex: 0.33;
  align-items: center;
}
.first_of_type {
  font-weight: bold !important;
  flex: 0.3;
}
.first_of_type2 {
  font-weight: bold !important;
  flex: 0.2;
}
.second_of_type {
  flex: 0.7;
}
.addinvoice_revenuedetails {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 950px) {
  .addinvoice_revenuedetails {
    flex-direction: column;
    align-items: inherit;
  }
  .invoicepaymentdetail {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 800px) {
  .invoiceincomesharing {
    flex-direction: column !important;
  }
  .invoicepaymentdetail {
    flex-direction: column !important;
  }
}
.addinvoice_revenuedetailsleft {
  display: flex;
  padding: 15px;
  padding-left: 0px !important;
}
@media only screen and (max-width: 700px) {
  .addinvoice_revenuedetailsleft {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 450px) {
  .invoice_enquiryportion {
    flex-direction: column !important;
  }
  .invoicepaymentinside {
    flex-direction: column !important;
  }
  .invoice_footer {
    flex-direction: column !important;
  }
}
.invoice_detailsbelowleftbelow {
  position: relative;
  height: 81px;
  width: 250px;
  margin-top: 15px;
  border: 1px dotted;
  padding: 5px;
  margin: 5px;
}
.netrevenue {
  position: absolute;
  right: 0;
}
.addinvoice_revenuedetailsright {
  flex: 0.5;
  padding: 15px;
  padding-right: 0 !important;
}
.addinvoice_revenuedetailright {
  display: flex;
  margin: 5px 0;
}
.addinvoice_revenuedetailrightfirst {
  color: cornflowerblue;
  flex: 0.6;
}
.addinvoice_detailinput {
  display: flex;
  flex: 0.6;
}
.materialtransform {
  text-transform: capitalize !important;
}
.inputalign {
  text-align: right !important;
}
.inputalign::placeholder {
  text-align: left !important;
}
.MuiInputBase-input {
  text-align: right !important;
}
.MuiSelect-selectMenu {
  text-align: left !important;
}
.hrmargin {
  margin: 0 !important;
}
.addinvoice_datedetail {
  display: flex;
  margin: 10px 0;
}
.quotation_table .MuiTableCell-root {
  padding: 10px 3px !important;
  color: #071A52 !important;
    font-family: "Source Sans Pro", sans-serif !important;
}
.addinvoice_clientdetails .row {
  flex-wrap: nowrap !important;
}
.viewinvoice_row {
  margin: 20px 0 !important;
}
.viewinvoice_row .row {
  margin: 20px 0 !important;
}
.viewinvoice_row2 {
  width: 100%;
}
.viewinvoice_row2 .row {
  margin: 20px 0 !important;
}
/* .invoicecurrency .select-currency > div > input {
  height: 40px !important;
  width: 100% !important;
  border: none !important;
} */
.invoicecurrency {
  margin: 10px 0 !important;
}
