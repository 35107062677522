@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  /* height: 12px;
  z-index: 0;
  width: 10px; */
  height: 8px;
  z-index: 0;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  /* background:#ccc; */
  border-radius: 10px;
  width: 5px;
  /* background: #f5f5f9;
  border-radius: 10px; */
}

::-webkit-scrollbar-thumb:hover {
  /* background:#ccc;
  border-radius: 10px;
  width: 5px; */
  background: #071A52;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  /* background: #d1c7ff33; */
  background: #e9ebf2;
}

/*Sangam Changes in ClientUser.js Page */

.newlisting_headertabdesignone {
  padding: 1.5rem;
  border-right: 1.5rem solid #e9ebf2;
  border-left: 1.5rem solid #e9ebf2;
  border-top: 1.5rem solid #e9ebf2;
  margin-bottom: 0;
}

.s_maindiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -17px;
}

.s_submaster {
  margin-top: -10px;
}

.s_select {
  border: none;
  padding-top: 5px;
  background-color: #f5f5f9;
  height: 45px;
}

.s_form-control {
  background-color: #f5f5f9;
  width: -webkit-fill-available;
}

.datepickerrangevaluetext {
  background-color: #f5f5f9;
}

.datepickerrangevaluetextone {
  background-color: #f5f5f9;
  padding: 10px;
  color: #6d7290;
  height: 40px;
}

.s_search {
  margin-top: 12px;
}

.newlisting_headertabdesigntwo {
  padding: 1.5rem;
  border: 1.5rem solid #e9ebf2;
  margin-top: 0;
  margin-bottom: 0;
}

.s_para {
  margin-top: 15px;
  color: #6D7290;
  margin-left: 10px;
}

.s_tr {
  color: #6d7290;
}

.s_newdiv {
  display: flex;
  margin: 10px 0;
  justify-content: flex-end;
}

.s_dropname {
  display: flex;
}

.s_danger {
  float: right;
  margin-right: 10px;
}

.head-breadcrum {
  margin-top: 20px;
}

/*Sangam Changes in ViewEnquiryWorkflowList.js Page */
.lead-page {
  margin-top: 50px;
}

.page-head {
  margin-top: 5rem;
}

.s_float {
  float: right;
}

.s_cursor {
  cursor: pointer;
}

.s_pagi {
  margin-top: -20px;
  margin-bottom: 20px;
}

.s_spanPage {
  margin-left: 90px;
  margin-top: -32px;
  color: #6d7290
}

.card.client-profile {
  min-width: 265px;
}

.closei {
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: 700px;
}

.s_upload {
  font-size: 20px;
  font-weight: bold;
  color: #530684;
}

.s_choosefile {
  color: #8B919D;
  font-size: 12px;
  line-height: 18px;
}

.s_inactive {
  background-color: #FF9999;
  color: #6d7290;
  ;
}

.primary-button {
  background: #17b978;
  color: #fff;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid #17b978;
  display: inline-block;
}

.primary-button:hover {
  background: transparent;
  color: #17b978;
}

.secondary-button {
  padding: 9px 13px;
  font-size: 14px;
  color: #6d7290;
  background: #eee;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid #eee;
  display: inline-block;
}

.secondary-button:hover {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.progress-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: 10px;
}

.progress-wrapper .progress {
  height: 3px;
}

.progress-wrapper p {
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  color: #6d7290;
}

.receipt {
  border-radius: 6px;
  border: 1px solid #eee;
  margin-bottom: 30px;
}

.receipt .top-header {
  --bs-gutter-x: 0 !important;
  background: #6d7290;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 20px;
  color: #fff;
}

.receipt .top-header h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.receipt .top-header p {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}

.receipt .content {
  padding: 20px;
}

.receipt .content h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #6d7290;
}

.receipt .content p {
  color: #071a52;
  font-weight: 600;
}

.summary {
  border: 1px solid #E9EBF2;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px;
}

.summary h3 {
  font-size: 20px;
  font-weight: 600;
}

.summary ul {
  margin: 0;
  padding: 0;
}

.summary ul li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #071a52;
  margin-bottom: 14px;
}

.summary ul li.green {
  color: #086972;
}

.summary ul li:last-child {
  margin-bottom: 0;
}

.summary ul li strong {
  font-weight: 700;
}

.profile-drop .dropdown-menu {
  color: black;
  font-size: 12px;
}

.s_client_limit {
  min-width: 72.63px;
  text-align: center;
}

.s_client_setting {
  min-width: 96.63px;
  text-align: center;
}

.s_client_datepickerrangevaluetextone {
  background-color: #f5f5f9;
  padding: 10px;
  color: #6d7290;
  height: 40px;
  min-width: 120.94px;
}

.s_client_form-control {
  background-color: #f5f5f9;
  width: 169.25px;
}

.s_client_form-controlone {
  background-color: #f5f5f9;
  width: 120.94px;
}

.s_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.s_admission_form-control {
  background-color: #f5f5f9;
  width: 207.25px;
}

.form-check .checkbox-group {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 5px 0;
}

.info-group {
  display: flex;
  min-height: 40px;
  margin: 10px auto;
}

.info-group label {
  min-width: 130px;
  font-weight: 600;
  color: #6d7290 !important;
}

.info-group span {
  font-weight: 600;
  color: #071a52;
}

.info-group span.box {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #e9ebf2;
  border-radius: 6px;
  flex: auto;
}

.info-group h5 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid crimson;
  color: crimson;
  border-radius: 6px;
}

.info-group h5.paid {
  color: #17b978;
  border: 2px solid #17b978;
}

.other-quotation-edit .info-group {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 10px auto;
}

.other-quotation-edit .info-group label {
  min-width: 130px;
  font-weight: 600;
  color: #6d7290;
}

.other-quotation-edit .info-group span {
  font-weight: 600;
  color: #071a52;
}

.other-quotation-edit .info-group span.box {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #e9ebf2;
  border-radius: 6px;
  flex: auto;
}

.discount label {
  color: #071a52;
  font-weight: 600;
}

.discount .total-box {
  display: inline-block;
  padding: 10px;
  border: 1px dashed #333;
  border-radius: 6px;
  flex: auto;
  margin: 5px;
}

.discount .total-box span {
  font-size: 20px;
}

.discount .total-box span.value {
  font-size: 50px;
  color: #071a52;
}

.check .form-check .form-check-input {
  min-width: 0;
}

.form-check.checkbox-group {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 5px 0;
}

/* .s_checkbox {
  background-color: #f5f9f5;
  font-size: 14px;
  border: none;
  color: #6d7290;
} */

/* .input.s_checkbox{
  background-color: pink;
  font-size: 14px;
  border: none;
  color: #6d7290;
} */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #f5f5f9;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #0d6efd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}

/* 
2nd check box
 */


.containerone {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containerone input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkone {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerone:hover input~.checkmarkone {
  background-color: #f5f5f9;
}


.containerone input:checked~.checkmarkone {
  background-color: #0d6efd;
}

.checkmarkone:after {
  content: "";
  position: absolute;
  display: none;
}


.containerone input:checked~.checkmarkone:after {
  display: block;
}


.containerone .checkmarkone:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}

/* 
3rd check box
 */


.containerthree {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containerthree input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkthree {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerthree:hover input~.checkmarkthree {
  background-color: #f5f5f9;
}


.containerthree input:checked~.checkmarkthree {
  background-color: #0d6efd;
}

.checkmarkthree:after {
  content: "";
  position: absolute;
  display: none;
}


.containerthree input:checked~.checkmarkthree:after {
  display: block;
}


.containerthree .checkmarkthree:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}



/* 
4th check box
 */


.containerfour {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containerfour input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkfour {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerfour:hover input~.checkmarkfour {
  background-color: #f5f5f9;
}


.containerfour input:checked~.checkmarkfour {
  background-color: #0d6efd;
}

.checkmarkfour:after {
  content: "";
  position: absolute;
  display: none;
}


.containerfour input:checked~.checkmarkfour:after {
  display: block;
}


.containerfour .checkmarkfour:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}


/* 
5th check box
 */


.containerfive {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containerfive input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkfive {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerfive:hover input~.checkmarkfive {
  background-color: #f5f5f9;
}


.containerfive input:checked~.checkmarkfive {
  background-color: #0d6efd;
}

.checkmarkfive:after {
  content: "";
  position: absolute;
  display: none;
}


.containerfive input:checked~.checkmarkfive:after {
  display: block;
}


.containerfive .checkmarkfive:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}


/* 
6th check box
 */


.containersix {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containersix input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarksix {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containersix:hover input~.checkmarksix {
  background-color: #f5f5f9;
}


.containersix input:checked~.checkmarksix {
  background-color: #0d6efd;
}

.checkmarksix:after {
  content: "";
  position: absolute;
  display: none;
}


.containersix input:checked~.checkmarksix:after {
  display: block;
}


.containersix .checkmarksix:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}


/* 
7th check box
 */


.containerseven {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containerseven input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkseven {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerseven:hover input~.checkmarkseven {
  background-color: #f5f5f9;
}


.containerseven input:checked~.checkmarkseven {
  background-color: #0d6efd;
}

.checkmarkseven:after {
  content: "";
  position: absolute;
  display: none;
}


.containerseven input:checked~.checkmarkseven:after {
  display: block;
}


.containerseven .checkmarkseven:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}

/* 
8th check box
 */


.containereight {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}


.containereight input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkeight {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containereight:hover input~.checkmarkeight {
  background-color: #f5f5f9;
}


.containereight input:checked~.checkmarkeight {
  background-color: #0d6efd;
}

.checkmarkeight:after {
  content: "";
  position: absolute;
  display: none;
}


.containereight input:checked~.checkmarkeight:after {
  display: block;
}


.containereight .checkmarkeight:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}

.s_tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #17b978;
}

.s_tooltip .s_tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: #17b978;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.s_tooltip:hover .s_tooltiptext {
  visibility: visible;
  margin-top: 35px;
  margin-left: -85px;
}

.sun-editor-editable {
  line-height: 1.2 !important;
}

.file-viewer-style {
  border: 10px solid green;
  width: 100%;
  height: 100%;
}

.my-apple {
  font-family: sans-serif;
  text-align: center;
  border: 10px solid green;
  width: 100%;
  height: 100%;
  display: "flex";
  object-fit: "contain";
  overflow: "hidden";
  padding-left: "120px";
}

.my-apple canvas {
  width: 75% !important;
  height: 100% !important;
}

/* .ulclass li {
  
} */

.ulclass li p {
  /* padding: 0px; */
  padding: 5px 16px 5px 16px;
}

.ulclass li :hover {
  background: #e9ebf2;
}

.aclass {
  -webkit-text-decoration: auto !important;
          text-decoration: auto !important;
  color: currentColor !important;
}

.static-table tbody tr:nth-child(odd) {
  background: #eee;
}

.static-table tbody tr:nth-child(even) {
  background: #fff;
}

.styles-module_tooltip__mnnfp {
  visibility: hidden;
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  will-change: opacity, visibility;
}

.s_ckeditor .ck-editor__main div {
  height: 485px !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  height: 485px !important;
}

.s_horiline {
  width: 140%;
  height: 15px;
  background-color: #e9ebf2;
  margin-left: -8%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-family: "Lato","Arial",sans-serif*/
}
form {
  display: block;
  position: relative;
}
.loginform {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #3d4c5a;
  padding: 60px 10px;
  overflow: auto;
  position: fixed;
}
.errormessage {
  color: red;
}
/*.App{
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #3d4c5a;
    padding: 60px 10px;
    overflow: auto;
    position: fixed;
}*/

.headclass {
  text-align: center;
  color: white;
  font-weight: 400;
  margin-bottom: 40px;
}

.headclass span {
  color: #01c0c8;
}

.form-inner h2 {
  font-size: 30px;
  color: #00c292;
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-inner {
  background: white;
  border-radius: 5px !important;
  margin: auto;
  padding: 20px !important;
  width: 360px;
}

.forgot_form_width {
  width: 400px;
}
.forgot_form_head {
  text-align: center;
}

.form-inner-admin {
  background: white;
  border-radius: 5px !important;
  /* padding: 0 20px !important; */
}

.form-group {
  margin-top: 15px;
}
.form-groupone {
  margin-top: 0px;
}
.creatadminone {
  margin-top: 10px;
}

.creatadmin {
  margin-top: 20px;
}

.creatadmin2 {
  margin-top: 20px;
}

.forminput {
  height: 40px;
  width: 100%;
}
.forminput2 {
  width: 100%;
  padding-top: 15px;
}

.form-group input {
  font-size: 16px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
}

.form-group select {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
}

.form-group input::placeholder {
  font-size: 12px;
}

.form-group label {
  font-size: 14px;
  font-weight: 600 !important;
  color: #071a52 !important;
}

.text-left {
  text-align: left !important;
}
.inline-block {
  display: inline-block !important;
}
.checkbox-custom,
.checkbox-custom-alt {
  cursor: pointer;
}
.checkbox-custom input,
.checkbox-custom-alt input {
  opacity: 0.5;
}
.btn.btn-dutch {
  width: 100%;
  height: 35px;
  font-size: 20px;
  background-color: #01c0c8;
  color: white;
  border-color: #01a8af;
  border-radius: 5px;
}
.btn.btn-dutch2 {
  width: 100%;
  height: 35px;
  background-color: #0069d9;
  color: white;
  border-color: #0069d9;
  border-radius: 5px;
}

.regdbtn {
  width: 20%;
  border-radius: 10px;
  font-size: 0px;
  background-color: #01c0c8;
  color: white;
  border-color: #01a8af;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.forgotclass {
  font-size: 13px;
  color: #23527c;
}
.cust-btn {
  padding: 8px 23px;
  font-size: 20px;
}

.editprofilebtn {
  padding: 10px 15px;
  margin-left: 20px;
}

.editprofilebtn2 {
  padding: 10px 15px;
  margin-top: 20px;
}

.mt-20crtofc {
  margin-top: 20px !important;
  padding: 10px 15px;
}

.responsive {
  margin-left: 80px;
  /* margin-left: 230px; */
  margin-right: 20px;
  margin-top: 88px;
  /* margin-top: -50px; */
}

.report_responsive {
  margin-left: 80px;
  /* margin-left: 230px; */
  margin-right: 30px;
  margin-top: 10px;
}

.responsive_client {
  margin-right: 20px;
  transition: all 0.5s ease;

  margin-top: 88px;
}

.responsive_client_application {
  margin: 10px;
  transition: all 0.5s ease;
}

.maindashboard_responsivetop {
  transition: all 0.5s ease;
  margin-right: 60px;
  margin-top: 100px;
}

@media only screen and (max-width: 600px) {
  .responsive {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .maindashboard_responsive {
    flex-direction: column !important;
  }
}

.leadpadding {
  padding-right: 9px !important;
}

.clock-holder {
  width: 100%;
  background: #fff;
  margin: 20px auto;
  position: relative;
}

.stopwatch {
  padding: 60px 0px;
  text-align: center;
}

.stopwatch span {
  background: #00aba9;
  color: #fff;
  display: inline-block;
  font-size: 22px;
  padding: 20px;
  border-radius: 5px;
  width: 70px;
}

.stopwatch .stopwatch-btn {
  background: #fff;
  margin-top: 30px;
  margin-left: 5px;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  text-transform: uppercase;
  width: 150px;
  transition: background 1s;
  cursor: pointer;
}

.stopwatch .stopwatch-btn-red {
  border: 2px solid #c85454;
  color: #c85454;
}

.stopwatch .stopwatch-btn-yel {
  border: 2px solid #ffc900;
  color: #ffc900;
}

.stopwatch .stopwatch-btn-gre {
  border: 2px solid #00aba9;
  color: #00aba9;
}

.stopwatch .stopwatch-btn-red:hover {
  background: #c85454;
  border: 2px solid #c85454;
  color: #fff;
}

.stopwatch .stopwatch-btn-yel:hover {
  background: #ffc900;
  border: 2px solid #ffc900;
  color: #fff;
}

.stopwatch .stopwatch-btn-gre:hover {
  background: #00aba9;
  border: 2px solid #00aba9;
  color: #fff;
}

.todo-list li {
  margin-left: 50px !important;
}

.ck-content li {
  margin-left: 30px;
}

.client_container2 {
  border-bottom: 5px solid #007bff;
  position: relative;
  width: 300px;
  line-height: 28px;
  overflow: hidden;
  margin-top: 35px;
  margin-bottom: 27px;
}

.client_container2 span {
  color: #fff;
  background: #007bff;
  font-weight: bolder;
  display: inline-block;
  /* padding: 5px 40px; */
  padding: 3px 30px 0 13px;
}

.client_container2 span::after {
  position: absolute;
  content: "";
  width: 19%;
  height: 84%;
  background: #007bff;
  transform: rotate(59deg);
}

.client_container {
  border-bottom: 5px solid #007bff;
  /* border-bottom: 5px solid #000; */
  position: relative;
  width: 300px;
  line-height: 28px;
  overflow: hidden;
  margin-top: 38px;
  margin-bottom: 27px;
  /* margin-left: -13px; */
}

.client_container span {
  color: #fff;
  background: #000;
  font-weight: bolder;
  background: #007bff;
  display: inline-block;
  /* padding: 5px 40px; */
  padding: 3px 30px 0 13px;
}

.client_container span::after {
  position: absolute;
  content: "";
  width: 19%;
  height: 84%;
  background: #007bff;
  transform: rotate(59deg);
}

.errormessage {
  background-color: white;
  color: red;
  text-align: left;
  padding: 2px;
  margin: 0 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.paddingright0 {
  padding-right: 0 !important;
}

.form-group {
  border: none !important;
  padding: 0 !important;
}

.form-group input {
  /* border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important; */
  border: none;
}

.form-group select {
  /* border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important; */
  border: none !important;
  width: 100%;
}

.newselect {
  /* border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important; */
  border: none !important;
  width: 100%;
}

.coursecol {
  padding: 0 3px !important;
}

.col-md-4 {
  padding: 0 3px !important;
}

.col-md-3 {
  padding: 0 5px !important;
}

.col-md-2 {
  padding: 0 5px !important;
}

.col-md-6 {
  padding: 0 3px !important;
}

.col-md-8 {
  padding: 0 3px !important;
}

.col-md-9 {
  padding: 0 3px !important;
}

.col-md-12 {
  padding: 0 3px !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.02em !important;
}

.providerpadding {
  padding: 0 3px !important;
}

/* .col-md-6 {
  padding: 0 5px !important;
} */
.nav-tabs .nav-link {
  color: #495057 !important;
  font-weight: bold;
}

.nav-tabs .nav-link.active {
  /* color: cornflowerblue !important; */
  color: #17b978 !important;
  font-weight: bold;
}

/* .ck.ck-editor__editable_inline> :first-child {
  font-size: 23px !important;
} */

.branch_buttonrow {
  margin: 20px 5px !important;
  float: right;
}

.master.MuiSelect-select {
  text-align: right !important;
}

.dashboardp {
  margin-bottom: 0 !important;
  margin-top: 5px !important;
}

.dashboardh {
  color: #6D7290 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  padding: 0.5rem !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  border: none !important;
  /* text-transform: capitalize !important; */
}

.location_status {
  margin-bottom: 0 !important;
  font-weight: bold;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.maindashboard_select .MuiInput-underline:before {
  border-bottom: 1px solid !important;
}

label {
  color: #2a4184 !important;
}

.quotation_input .MuiInputBase-input {
  text-align: left !important;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.makeStyles-drawerClose-70 {
  width: 60px !important;
}

.notes_dummy .MuiSelect-select {
  /* width: 90px; */
  border-bottom: 1px solid !important;
}

.dummy3_attachment_radio .MuiFormControl-root {
  display: contents !important;
}

.clientgeneralinvoicetables .MuiTableCell-root {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.MuiDrawer-paper {
  overflow-y: scroll !important;
}

.MuiDrawer-paper::-webkit-scrollbar {
  width: 10px !important;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  /* background: rgb(0, 174, 255) !important; */
  background: #17b978 !important;
  border-radius: 10px;
}

.newdashboard_icon .MuiListItem-root.Mui-selected {
  background-color: #17b978 !important;

  /* background-color: rgb(0, 174, 255) !important; */
  /* background-color: lightblue !important; */
}

.MuiTypography-colorPrimary {
  color: #071A52 !important;
}

.MuiTypography-h3 {
  font-size: 50px !important;
  color: #071a52 !important;
}

.MuiSvgIcon-colorPrimary {
  color: #17b978 !important;
}

.card-body {
  padding: 20px !important;
}

.card-body-sangam {
  padding: 20px !important;
}

.card-body-san {
  padding: 20px !important;
}

.client_datefilterinput {
  padding: 0 20px !important;
}

.quotation_margin_row {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.adminusers_row .col {
  padding: 0 !important;
}

.MuiInput-root {
  /* border: 1px solid #ced4da !important; */
  border: none !important;
  background-color: #f5f5f9 !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.makeStyles-formControl-21 {
  border: none !important;
}

.WithStyles\(ForwardRef\(AccordionSummary\)\)-root-74 {
  background-color: "#f5f5f9" !important;
}

.MuiAppBar-colorPrimary {
  color: #0000008A !important;
  background-color: #fff !important;
}

.newdashboard_icon .MuiTypography-body1 {
  color: #e0e1ea;
}

.newdashboard_icon {
  background-color: #262a2e !important;
}

.newdashboard_icon .MuiSvgIcon-root {
  color: #e0e1ea;
}

.WithStyles\(ForwardRef\(AccordionSummary\)\)-root-74 {
  background-color: #f5f5f9 !important;
}

.newdashboard_backgroundcolor .MuiPaper-root {
  background-color: #262a2e !important;
}

#myForm>div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fonticonpositioning {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4em;
  color: #94b2c6;
  cursor: pointer;
}

.rct-options {
  height: 50px !important;
  position: absolute !important;
  left: -34px !important;
  top: -30px !important;
}

.roleabsoluteposition {
  height: 50px;
  width: 50px !important;
  position: absolute !important;
  top: -30px;
  left: 25px;
}

.provideradmin {
  margin: 10px 0 !important;
}

.form_inner_admin hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.rolesrowalignment {
  margin-left: 35px !important;
}

.provider_containertitle {
  font-size: 20px;
  color: #071A52;
  margin-bottom: -5px !important;
  font-weight: bold;
}

.provider_containertitle hr {
  margin-bottom: -5px !important;
}

/* .ReactModal__Overlay--after-open {
  background-color: #00000705 !important;
} */
/* .ReactModal__Overlay--after-open {
  /* background-color: transparent !important; 
} */
.master .MuiInput-root {
  border-radius: 0.25rem !important;
  height: 45px !important;
}

.area_rowslable {
  /* margin: 0 10px !important; */
  /* font-weight: bold; */
  /* color: #071A52 !important; */
  color: #212529 !important;
  margin-bottom: 0.0rem;
}

.area_rowslable_skill {
  /* margin: 0 10px !important; */
  font-weight: bold;
  color: #071A52 !important;
  /* color: #212529 !important; */
}

area_rowslable_my {
  color: #212529 !important;
  margin-bottom: 0.5rem;
  font-size: 14px !important;
}

.select__control {
  height: "16px" !important;
}

.form-control {
  font-size: 14px !important;
  background-color: #f5f5f9;
  border: none !important;
  color: #6d7290 !important;
}

.area_rowslableRight {
  margin: 13 10px !important;
  font-weight: bold;
  color: #2a4184;
  float: right;
}

.master .MuiFormControl-root {
  margin-top: 0px !important;
}

.formfooterrecordstyle {
  margin-top: 20px;
}

.viewproviderptag {
  margin-bottom: 0px !important;
}

.providerreport {
  margin: 30px 0 !important;
}

.MuiCheckbox-root {
  padding: 9px 2px 9px 9px !important;
}

.workflowcol .col {
  padding: 0 5px !important;
}

.viewworkflowheader {
  width: 72vw !important;
}

.viewworkflowheader2 {
  width: 66vw !important;
}

/* .dummy_contentright .MuiAppBar-root:first-child {
  width: 66vw !important;
} */
.CircularProgressbar .CircularProgressbar-path {
  stroke: orange !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: orange !important;
}

.accordiondetails {
  margin-right: 5px !important;
}

.notesformmgroup {
  margin-top: 0 !important;
}

/* .select-currency>div>input {
  height: 40px !important;
  width: 100% !important;
  background-color: #f5f5f9 !important;
} */

.MuiListItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

#dummy2_middlecontent .MuiTab-textColorInherit.Mui-selected {
  background-color: #3f51bf !important;
  color: #fff !important;
}

#dummy2_middlecontent .MuiAppBar-root {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #cdc9c9 !important;
  border-bottom: 1px solid !important;
}

#dummy2_middlecontent .MuiTab-root {
  border-right: 1px solid !important;
}

.dummy_contentright .Mui-selected {
  background-color: #fff !important;
  color: #6D7290 !important;
}

.Mui-selected:hover {
  color: #17b978 !important;
}

.dummy_contentright .MuiAppBar-root {
  /* color: rgba(0, 0, 0, 0.87) !important; */
  /* background-color: #cdc9c9 !important; */
  background-color: #fff !important;
  /* border-bottom: 1px solid !important; */
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1pxsolidrgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
}

.PrivateTabIndicator-colorPrimary-63 {
  background-color: #17b978 !important;
}

#course_view_topcontent .Mui-selected {
  background-color: #3f51bf !important;
  color: #fff !important;
}

#course_view_topcontent .MuiAppBar-root {
  color: rgba(0, 0, 0, 0.87) !important;
  /* background-color: #cdc9c9 !important; */
  border-bottom: 1px solid !important;
}

/* #course_view_topcontent .MuiTab-root {
  border-right: 1px solid !important;
} */

.nobordertab {
  flex: 1 1 !important;
}

.ck-content {
  min-height: 250px !important;
}

.workflowdashboard_chart {
  /* min-height: 150px; */
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  overflow: hidden;
  margin: 5px;
  flex: 0.55 1;
}

.enquirynotesdashboard_note {
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 10px !important;
  overflow: auto;
  height: 220px;
  background-color: #fff;
  position: relative;
}

.workflowdashboard_clientnote {
  background: white;
  /* box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%); */
  padding: 8px !important;
  /* overflow: auto;
  height: 100px; */
  background-color: #fff;
  position: relative;
}

.workflowdashboard_absolute {
  width: 98%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  min-height: 38px;
  background: #eeecee;
  padding: 0 20px;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  align-items: center;
}

.workflowdashboard_fa-ellipsis {
  cursor: pointer;
  padding: 5px 10px;
  transition: all 0.3s ease;
}

.workflowdashboard_fa-ellipsis:hover {
  background-color: #17b978;
  /* border-radius: 50%; */
}

.newdashboard_icon .MuiTypography-body1 {
  line-height: 0.8 !important;
}

.dummymargin {
  margin-bottom: 0 !important;
}

.collegeimage {
  width: 100px !important;
  height: 84px !important;
  border-radius: 0 !important;
}

.leadellipsis {
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  /* This add an ellipsis i.e ... if the text increases too long */
  text-overflow: ellipsis;
  /* position: relative; */
  display: block;
  height: 70px;
}

.leadellipsisspan {
  position: absolute;
  cursor: pointer;
  left: 10px;
  color: #ffc900;
  font-weight: bold;
  bottom: 0px;
}

.providerlist_logo {
  background-color: orange !important;
  width: 70px !important;
  height: 50px !important;
  border-radius: 0px !important;
  position: relative !important;
}

.no_logo {
  background-color: orange !important;
  width: 120px !important;
  height: 120px !important;
  border-radius: 40% !important;
  position: relative !important;
  margin-left: 50px !important;
}

.workflowdashboarddropdown {
  min-width: 80px !important;
}

.sang_workflowdashboarddropdown {
  min-width: 140px !important;
}

.workflowdashboarddropdown p {
  padding-left: 20px;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.sang_workflowdashboarddropdown p {
  padding-left: 20px;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.sangam_workflowdashboarddropdown {
  min-width: 180px !important;
}

.dropdown-menu.show {
  width: -webkit-max-content;
  width: max-content;
}

.printinvoice_divlisting {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.printinvoice_divlistingtextfirst {
  font-weight: bolder;
  flex: 0.7 1;
  margin-left: 20px;
}

.nomarginbottom {
  margin-bottom: 0 !important;
}

.printinvoice_divlistingtextsecond {
  font-weight: bolder;
  flex: 0.3 1;
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

.printinvoice_paymentdetails {
  height: 50px;
  display: flex;
  align-items: center;
}

.printinvoice_headerrightbox {
  display: flex;
  justify-content: space-between;
}

.printinvoice_headerrightbox h6 {
  font-size: 20px;
}

.printinvoice_headerrightboxnew {
  display: flex;
  margin-bottom: 5px;
}

.print_middle thead tr {
  background-color: #737373;
  /* background-color: rgba(0, 0, 0, 0.4) !important; */
}

.print_middle .MuiTableCell-root {
  border: none !important;
  font-size: 20px;
}

.print_middle thead tr th {
  color: white;
}

.printinvoice_tr tr {
  background-color: #d9d9d9 !important;
}

.printinvoice_tr tr th {
  color: #000 !important;
}

.printinvoice_horiline {
  border-top: 2px solid #737272 !important;
}

.printinvoice_headerrightboxnew p {
  font-weight: bold;
  font-size: 20px;
}

.print_invoicetotaldue p {
  font-weight: bolder;
  font-size: 25px;
}

.printinvoice_headerrightboxtop {
  right: 30px;
  margin: 10px;
  /* border: 1px solid; */
  padding-top: 20px;
  padding-bottom: 20px;
}

.printinvoice_header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.dateptag {
  position: absolute;
  top: 40px;
  z-index: 1000;
  left: 10px;
}

.dummy2_datepicker {
  margin-right: 7px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.addinvoice_datedetail .react-datepicker-wrapper {
  width: 100%;
  flex: 0.5 1;
}

.invoicecurrency .react-datepicker-wrapper {
  width: auto;
}

.invoicecurrency .form-control {
  width: 200px !important;
}

.dummy2_datepicker .dateptag {
  top: 11px;
}

.clientgeneralinvoicetables .dateptag {
  top: 23px;
}

.dummy2_datepicker .col-md-9 {
  padding: 0 !important;
}

.dummy2_datepicker .col-md-3 {
  padding: 0 !important;
}

.react-datepicker-wrapper input {
  width: 100%;
  border-radius: 4px !important;
  height: 37px;
  background-color: #f5f5f9 !important;
  border: none !important;
}

.datepickerrangevaluetext {
  /* border: 1px solid #ccc; */
  padding: 6px;
  border-radius: 3px;
  margin-bottom: 0 !important;
  cursor: pointer;
  height: 40px !important;
}

.datepickerrangevaluetext2 {
  /* border: 1px solid #ccc; */
  padding: 6px;
  border-radius: 3px;
  margin-bottom: 0 !important;
  cursor: pointer;
  height: 45px !important;
}

/* .clearsearchclass {
  display: flex !important;
  align-items: center !important;
} */
.clearsearchclass a {
  cursor: pointer;
}

.clearsearchclass input {
  background-color: #f0f2f5 !important;
  height: 45px;
}

.dashboardnodataptag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62%;
}

.printinvoice_footerimage {
  position: absolute;
  z-index: 0;
  height: 250px;
  width: 330px;
  bottom: 10px;
  opacity: 0.2;
  left: 10px;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 35px !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -webkit-user-select: none;
          user-select: none;
}

.amount_rowslabel {
  margin: 14px !important;
  font-weight: bold;
  color: #2a4184;
  float: right;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  background-color: #f5f5f9 !important;
  height: 40px !important;
  border: none !important;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
  height: 40px !important;
  margin-top: -5px !important;
}

.nav-tabs {
  border: none !important;
}
/*body {
    margin: 0px;
    padding: 20px;
    font-family: sans-serif;
    background-color: #cccccc;
  }
  
  */
.example {
  border-left: 1px solid #999999;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #999999;
  border-radius: 10px;
  /* margin: 0px; */
  padding: 20px;
  margin-left: 20px;
}

.item {
  border-top: 1px solid #ffffff;
  background-color: #f2f2f2;
  border-bottom: 1px solid #999999;
  border-radius: 6px;
  margin-bottom: 4px;
  padding: 10px;
  padding-left: 8px;
  opacity: 1;
  /* new added */
  /* width: 162px; */
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: left;
  cursor: move;
  height: 50px;
  /* width: 150px; */
  /* color: black; */
  /* background-color: #ccc; */
  /* padding: 5px; */
  border-bottom: 1px solid #ddd;
  border-radius: 5px;
  border: 2px solid #666666;
}

.dragindicator {
  float: left;
}

.item .title {
  font-weight: bold;
  font-size: 125%;
  margin-top: 7px;
  margin-bottom: 0.3rem;
}

.item .dlt {
  /* font-weight: bold;
    font-size: 125%; */
  margin-top: 7px;
  float: right;
  cursor: pointer;
  /* margin-bottom: 0.3rem; */
}

.item .lefticon {
  float: left;
  font-weight: bold;
  font-size: 175%;
  margin-top: 7px;
  margin-bottom: 0.3rem;
  margin-left: 10px;
}

.item .body {
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .small {
  font-size: 66%;
  text-align: right;
}

.item .small2 {
  text-align: right;
}

.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}
.toolbar {
  margin-left: 300px;
  margin-right: 60px;
}
.createBtn {
  float: left;
  color: #ffffff;
  text-decoration: none;
}
.headtext {
  font-size: 30px;
}
.createBtn2 {
  padding: 10px;
  color: white;
  background: #6c757d;
  border-radius: 5px;
}
.btnmargin {
  margin-bottom: 10px;
  margin-left: 20px;
}

.btnmargin2 {
  float: right;
}

.addsubmit {
  margin-top: 10px;
  padding: 10px 15px;
}

.dashboard {
  height: 60px !important;
}

.dummy_content {
  margin-top: 74px;
  transition: all 0.5s ease;

  /* display: flex; */
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .dummy_content {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .dummy_content {
    flex-direction: column;
  }
}

.dummy_contentleft {
  /* flex: 0.2; */
  background: white;
  margin: 5px;
  padding: 10px;
  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
}

.dummy_contentright {
  /* flex: 0.8; */
  /* background: white; */
  margin: 5px;
  transition: all 0.5s ease;

  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
}

.dummy_contentleftheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabpanel {
  /* background-color: white; */
  margin: 10px;
}

.icon-margin {
  margin-left: 5px;
  margin-right: 5px;
}

.dummy_contentleftheader>p {
  color: white;
  background: blue;
  padding: 5px;
  border-radius: 5px;
  /* border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%; */
}

.margin {
  margin-top: 10px;
}

.marginleft {
  margin: 10px;
}

.dummy_contentlefticons {
  display: flex;
  justify-content: space-between;
}

.dummy_contentlefticon {
  border: 1px solid;
  border-radius: 100%;
  padding: 2px;
}

.font {
  font-size: 0.9rem !important;
}

.bold {
  margin-bottom: 0 !important;
}

.dummy_leftbutton {
  display: flex;
  margin-bottom: 10px;
}

.dummy_contentrighttabs {
  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
  margin: 5px;
  background: white;
  display: flex;
}

.dummy_contentrightdetail {
  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
  margin: 5px;
  background: white;
}

.MuiTab-wrapper {
  text-transform: capitalize;
  font-weight: bolder;
}

.MuiBadge-dot {
  z-index: 0 !important;
}

.content-wrapper .card.client-profile .minimize-profile span {
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  color: #b1b1ca;
  transition: all 0.3s;
}

.content-wrapper .card.client-details .maximize-profile span {
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  color: #b1b1ca;
  transition: all 0.3s;
}

.content-wrapper .card.client-profile .minimize-profile span:hover {
  color: #071a52;
  transform: translateX(-5px);
}

.content-wrapper .card.client-details .maximize-profile span:hover {
  color: #071a52;
  transform: translateX(5px);
}
.dummy2 {
  margin-top: 15px;
  margin-left: 10px;
}
.dummy2_header {
  display: flex;
  justify-content: space-between;
}
.dummy2_typography {
  display: flex;
  padding: 10px;
  margin-right: 10px !important;
}
.dummy2_coursecontents {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.dummy2_coursecontent {
  flex: 0.33 1;
}
.dummy2_middle {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
@media only screen and (max-width: 1000px) {
  .dummy2_middle {
    flex-direction: column;
  }
}
#dummy2_middlecontent {
  flex: 0.7 1;
  margin: 5px;
}
.dummy2_middlecontent2 {
  margin: 5px;
  /* width: 270px; */
  /* flex: 0.3; */
}
.dummy2_productfee {
  display: flex;
  justify-content: space-between;
}

.accordion_name {
  display: flex;
}

.accordion_namebutton {
  flex: 0.1 1;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.accordion_radio {
  display: flex;
  background-color: rgba(245, 245, 249, 0.03);
}

.accordion_radio1 {
  flex: 0.1 1;
}

.dummy3_accordion {
  flex: 0.8 1;
}

@media only screen and (max-width: 800px) {
  .dummy3_accordion {
    flex: 0.7 1;
  }
}

@media only screen and (max-width: 400px) {
  .dummy3_accordion {
    flex: 0.5 1;
  }
}

.accordion_radio2 {
  flex: 1.9 1;
  background-color: none;
}

.MuiAccordionDetails-root {
  display: block;
}
.MuiPaper-elevation4{
  box-shadow: none !important;
}
.accordiondetails {
  margin-left: 30px;
  margin-right: 15px;
}
.summary{
  border: 1px solid #E9EBF2;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 30px;
}

.logs {
  display: flex;
  justify-content: space-between;
}

.log_item1 {
  flex: 0.8 1;
}

.log_item2 {
  flex: 0.2 1;
}

.cancel_margin {
  margin-left: 20px;
}

.positioning {
  position: relative;
  top: 15px;
}

.color {
  color: green;
}

.step_complete_css {
  /* margin-top: 10px !important; */
  /* margin-bottom: 10px !important; */
  background: #eee !important;
  color: #6d7290 !important;
  padding: 9px 13px !important;
  font-size: 14px !important;
  margin-left: 10px !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  border: none !important;
}
.mainnote {
  /* width: 800px; */
  width: 100%;
}
.notes {
  display: flex;
  justify-content: space-between;
}
.notes1 {
  display: flex;
  flex: 0.4 1;
  margin: 5px;
}
.appointment1 {
  display: flex;
  flex: 0.7 1;
  margin: 5px;
}
.attach1 {
  flex: 0.2 1;
  margin: 5px;
}
.attach2 {
  flex: 0.8 1;
  display: flex;
  margin: 5px;
}
.notes12 {
  padding: 5px;
  flex: 0.2 1;
}
.notes13 {
  padding: 5px;
  flex: 0.6 1;
}
.notes2 {
  display: flex;
  flex: 0.6 1;
  overflow: hidden;
  margin: 5px;
}
.appointment2 {
  display: flex;
  flex: 0.3 1;
  overflow: hidden;
  margin: 5px;
}
.notes21 {
  padding: 5px;
  flex: 0.6 1;
}
.notes22 {
  padding: 5px;
  flex: 0.3 1;
}
.appointment22 {
  padding: 5px;
  flex: 1 1;
}
.hr {
  margin-top: 0 !important;
}
.hr2 {
  margin-bottom: 0 !important;
}
.notes_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notes_appointment {
  display: flex;
  flex: 0.6 1;
  justify-content: space-evenly;
}
.notes_appointment2 {
  display: flex;
  flex: 1 1;
  justify-content: space-evenly;
}
.attachment_name {
  padding: 5px;
  flex: 0.4 1;
}

.searchInputs {
    display: flex;
}

.search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 300px;
}

.searchIcon {
    /* height: 60px;
    width: 50px; */
    background-color: white !important;
    display: grid;
    place-items: center;
    color: cadetblue;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-top: 5px;
    width: 100%;
    max-height: 300px;
    min-height: 50px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
}

/* .dataResult::-webkit-scrollbar {
    display: none;
} */

.dataResult .dataItem {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.dataItem p {
    margin-left: 10px;
    margin-top: 10px;
}

a {
    text-decoration: none;
}

a:hover {
    background-color: lightgrey;
}

#clearBtn {
    cursor: pointer;
}
body {
  -webkit-print-color-adjust: exact !important;
}
.addinvoice {
  background-color: white;
  padding: 20px;
  /* border: 1px solid; */
  transition: all 0.5s ease;

  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
  margin-top: 74px;
  /* margin-top: -50px; */
  margin-right: 20px;
}
.addinvoice_header {
  display: flex;
  justify-content: space-between;
}
.addinvoice_headerbutton {
  display: flex;
}
.addinvoice_details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1200px) {
  .addinvoice_details {
    flex-direction: column-reverse;
  }
}
.addinvoice_clientdetails {
  /* border: 1px solid black; */
  /* margin: 5px; */
  flex: 0.4 1;
  /* width: 300px; */
  /* padding: 15px; */
}

.addinvoice_clientdetail {
  display: flex;
  margin: 15px 5px;
}
.addinvoice_status {
  /* padding: 8px;
  border: 3px solid red;
  font-weight: bolder !important;
  color: red;
  text-align: center;
  width: 100%; */
  font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 10px;
    border: 2px solid crimson;
    color: crimson;
    border-radius: 6px;
    align-items: center;
    text-align: center;
    width: 6rem;
}
.addinvoice_status2 {
  /* padding: 8px;
  border: 3px solid green;
  font-weight: bolder !important;
  color: green;
  width: 100%;
  text-align: center; */
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 10px;
  border: 2px solid green;
  color: green;
  border-radius: 6px;
  align-items: center;
  text-align: center;
  width: 6rem;
  /* transition: transform 0.5s ease; */
}
/* .addinvoice_status:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.addinvoice_status2:hover {
  cursor: pointer;
  transform: scale(1.2);
} */
.addinvoice_headerdatedetails {
  margin-bottom: 10px;
  flex: 0.34 1;
  align-items: center;
}
.viewinvoice_headerdatedetails {
  margin-bottom: 10px;
  flex: 0.33 1;
  align-items: center;
}
.first_of_type {
  font-weight: bold !important;
  flex: 0.3 1;
}
.first_of_type2 {
  font-weight: bold !important;
  flex: 0.2 1;
}
.second_of_type {
  flex: 0.7 1;
}
.addinvoice_revenuedetails {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 950px) {
  .addinvoice_revenuedetails {
    flex-direction: column;
    align-items: inherit;
  }
  .invoicepaymentdetail {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 800px) {
  .invoiceincomesharing {
    flex-direction: column !important;
  }
  .invoicepaymentdetail {
    flex-direction: column !important;
  }
}
.addinvoice_revenuedetailsleft {
  display: flex;
  padding: 15px;
  padding-left: 0px !important;
}
@media only screen and (max-width: 700px) {
  .addinvoice_revenuedetailsleft {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 450px) {
  .invoice_enquiryportion {
    flex-direction: column !important;
  }
  .invoicepaymentinside {
    flex-direction: column !important;
  }
  .invoice_footer {
    flex-direction: column !important;
  }
}
.invoice_detailsbelowleftbelow {
  position: relative;
  height: 81px;
  width: 250px;
  margin-top: 15px;
  border: 1px dotted;
  padding: 5px;
  margin: 5px;
}
.netrevenue {
  position: absolute;
  right: 0;
}
.addinvoice_revenuedetailsright {
  flex: 0.5 1;
  padding: 15px;
  padding-right: 0 !important;
}
.addinvoice_revenuedetailright {
  display: flex;
  margin: 5px 0;
}
.addinvoice_revenuedetailrightfirst {
  color: cornflowerblue;
  flex: 0.6 1;
}
.addinvoice_detailinput {
  display: flex;
  flex: 0.6 1;
}
.materialtransform {
  text-transform: capitalize !important;
}
.inputalign {
  text-align: right !important;
}
.inputalign::placeholder {
  text-align: left !important;
}
.MuiInputBase-input {
  text-align: right !important;
}
.MuiSelect-selectMenu {
  text-align: left !important;
}
.hrmargin {
  margin: 0 !important;
}
.addinvoice_datedetail {
  display: flex;
  margin: 10px 0;
}
.quotation_table .MuiTableCell-root {
  padding: 10px 3px !important;
  color: #071A52 !important;
    font-family: "Source Sans Pro", sans-serif !important;
}
.addinvoice_clientdetails .row {
  flex-wrap: nowrap !important;
}
.viewinvoice_row {
  margin: 20px 0 !important;
}
.viewinvoice_row .row {
  margin: 20px 0 !important;
}
.viewinvoice_row2 {
  width: 100%;
}
.viewinvoice_row2 .row {
  margin: 20px 0 !important;
}
/* .invoicecurrency .select-currency > div > input {
  height: 40px !important;
  width: 100% !important;
  border: none !important;
} */
.invoicecurrency {
  margin: 10px 0 !important;
}

.invoice {
  background-color: white;
  padding: 20px;
  /* border: 1px solid; */
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
  margin-top: -30px;
}

.invoice_header {
  display: flex;
  justify-content: space-between;
}
.invoice_headerbutton {
  display: flex;
}
.invoice_details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.invoice_detailleft {
  display: flex;
  padding: 5px;
  flex: 0.6 1;
}
.invoice_detailleftfirst {
  border: 1px solid black;
  margin: 5px;
  width: 300px;
  padding: 5px;
}
.invoice_detailleftsecond {
  border: 1px solid black;
  width: 300px;
  margin: 5px;
  padding: 5px;
}
.invoice_detailright {
  flex: 0.3 1;
}
.invoice_detailleftprovider {
  display: flex;
  justify-content: space-between;
}
.first_of_type {
  flex: 0.3 1;
}
.second_of_type {
  flex: 0.7 1;
}

.invoice_detailsbelow {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
}
.invoice_detailsbelowleft {
  display: flex;
  padding: 15px;
  flex-direction: column;
}
.invoice_detailsbelowleftup {
  display: flex;
  margin: 5px;
}
.invoice_detailsbelowright {
  padding: 15px;
}
.invoice_belowrightdetail {
  display: flex;
  margin: 5px;
}
.invoice_belowrightdetailfirst {
  flex: 0.4 1;
}
.invoice_detailinput {
  display: flex;
  flex: 0.6 1;
}

.invoice_detailsbelowleftbelow {
  /* position: relative;
  height: 81px;
  width: 250px;
  margin-top: 15px;
  border: 1px dotted;
  padding: 5px; */
  display: inline-block;
    padding: 10px;
    border: 1px dashed #333;
    border-radius: 6px;
    flex: auto;
    margin: 5px;
    height: 120px;
}

.head-style{
  font-size: 20px !important;
}
.netrevenue {
  position: absolute;
  right: 0;
}
.invoice_detailinput input {
  text-align: right;
}

.maindash_header {
  background: transparent;
  margin-top: -30px;
}
.maindash_headercomponent {
  display: flex;
  justify-content: space-between;
}
.office_dropdown {
  margin-top: -10px !important;
  margin-left: 5px !important;
}
.bodytop {
  margin-top: 20px;
}
.bodytop_top {
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 20px;
  /* margin-left: 5px;
  margin-right: 5px; */
  margin: 5px;
  flex: 0.25 1;
}
.bodytop_topitem {
  display: flex;
  align-items: center;
}
.body_top {
  min-height: 150px;
  border: 1px solid #d5d5db;
  padding: 20px;
  flex: 0.55 1;
  border-radius: 5px;
  overflow: auto;
}
.body_topright {
  min-height: 150 px;
  border: 1px solid #d5d5db;
  padding: 20px;
  flex: 0.55 1;
  margin-left: 5px;
  border-radius: 5px;
  overflow: auto;
}
.bootstrapcoldashboard {
  padding: 20px !important;
}
.body_top2 {
  min-height: 300px;
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  flex: 1 1;
}
.bodymiddle {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #d5d5db;
  border-radius: 5px;
  padding: 20px;
}
.bodytop_icon {
  margin-right: 15px;
}
.bodytop_icontext {
  margin-left: 30px;
}
.application1 {
  flex: 0.1 1;
  padding: 10px;
  border-right: 1px solid black;
  margin: 5px !important;
}
.application2 {
  flex: 0.7 1;
  margin: 5px !important;
}
.applicationellipsis {
  width: 100px !important;
  white-space: nowrap;
  overflow: hidden;
  /* This add an ellipsis i.e ... if the text increases too long */
  text-overflow: ellipsis;
}
.paddingbutton {
  padding: 0 !important;
}
.bodymiddle_reminder {
  height: 330px;
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 20px;
  margin: 5px;
  flex: 0.55 1;
}
.box {
  /* width: 80%; */
  /* border: 2px dashed; */
  height: 250px;
  overflow-y: scroll;
  scroll-behavior: smooth; /* <--- */
}
.dashboardmiddle_below {
  display: flex;
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
}
.dashboardmiddle_belowone {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #d5d5db;
  border-radius: 5px;
  margin-left: 5px;
  padding: 20px;
  min-width: 200px;
  overflow: auto;
}
.bodytop_innercontainer {
  border: 1px solid #d5d5db;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  /* margin: 0 5px;
  margin-right: 0; */
  width:140px;
  height:140px;
  border-radius: 16px;
  margin:0 20px 20px 0;
}
.bodytop_innercontainer h4 {
  color: #2a4184;
  margin-bottom: 0 !important;
}
.bodytop_innercontainer h1 {
  font-size: 60px;
  color: #6cc1dd;
}
.maindashboard_select {
  margin-bottom: 10px;
}

* {
  font-family: "Source Sans Pro", sans-serif;
  /* font-size: 14px; */
  letter-spacing: 0.02em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.discount_type input {
  display: inline-block;
  height: 10px !important;
}

.header_dropdown {
  height: 38px !important;
  width: 100%;
  background: white !important;
}

.css-5duvv3-control {
  height: 38px !important;
  width: 100%;
  border: none !important;
}

.css-g1d714-ValueContainer {
  height: 38px !important;
  width: 100%;
}

.header_dropdown .css-yk16xz-control {
  height: 38px !important;
  border-width: 0px !important;
}

.header_dropdown .css-1hwfws3 {
  height: 38px !important;
  padding-top: 2px !important;
}

.header_dropdownone {
  height: 30px !important;
}

.header_dropdownone .css-yk16xz-control {
  height: 30px !important;
  border-width: 0px !important;
}

.header_dropdownone .css-1hwfws3 {
  height: 30px !important;
  padding-top: 2px !important;
}

.brach_dropdown {
  height: 40px !important;
  width: 100%;
  background-color: #f5f5f9 !important;
}

.brach_dropdown .css-yk16xz-control {
  height: 40px !important;
  border-width: 0px !important;
  background-color: #f5f5f9 !important;
}

.brach_dropdown .css-1hwfws3 {
  height: 40px !important;
  padding-top: 2px !important;
  background-color: #f5f5f9 !important;
}

.report_dropdown {
  height: 40px !important;
  width: 95%;
  background-color: #f5f5f9 !important;
}

.report_dropdown .css-yk16xz-control {
  height: 40px !important;
  border-width: 0px !important;
  background-color: #f5f5f9 !important;
}

.report_dropdown .css-1hwfws3 {
  height: 40px !important;
  padding-top: 2px !important;
  background-color: #f5f5f9 !important;
}

.ins_dropdown {
  height: 40px !important;
  width: 100%;
  background-color: #f5f5f9 !important;
}

.ins_dropdown .css-yk16xz-control {
  height: 40px !important;
  border-width: 0px !important;
  background-color: #f5f5f9 !important;
}

.ins_dropdown .css-1hwfws3 {
  height: 40px !important;
  padding-top: 2px !important;
  background-color: #f5f5f9 !important;
}

/* .header_dropdownone{
  height: 30px !important;
}
.header_dropdownone .css-yk16xz-control {
  height: 30px !important;
  border-width: 0px !important;
}
.header_dropdownone .css-1hwfws3 {
  height: 30px !important;
  padding-top: 2px !important;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8;
}

.fp-container .fp-loader {
  top: 30%;
  left: 40%;
  z-index: 1000;
  position: absolute;
}

.PrivateTabIndicator-colorPrimary-70 {
  background-color: #17b978 !important;
}

.MuiButton-outlinedPrimary {
  color: #17b978 !important;
  border: 1px solid #17b978 !important;
}

.account_sidebar {
  background-color: #55aad3;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  flex: 0.15 1;
  transition: all 0.4s ease-in-out;
}

.account_sidebarcompress {
  background-color: #55aad3;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  flex: 0.03 1;
  overflow-x: hidden;
  transition: all 0.4s ease-in-out;
}

.account_sidebar::-webkit-scrollbar,
.account_sidebarcompress::-webkit-scrollbar {
  height: 10px;
  z-index: 0;
  width: 8px;
}

.tab-bold {
  font-weight: bold !important;
  font-size: 16px !important;
}

.account_sidebar::-webkit-scrollbar-thumb,
.account_sidebarcompress::-webkit-scrollbar-thumb {
  background: #55aad3;
  border-radius: 10px;
}

.account_sidebar::-webkit-scrollbar-track,
.account_sidebarcompress::-webkit-scrollbar-track {
  background: #d1c7ff33;
}

.account_sidebarimgcontainer {
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.account_sidebarimgcontainer img {
  cursor: pointer;
  height: 60px;
}

.sorting {
  /* display: block; */
  content: " ";
  /* position: absolute; */
  top: 50%;
  right: 0;
  /* -webkit-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  background-size: 12px 12px;
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin-left: 2px;
  color: #071a52;
}

.PrivateTabIndicator-colorPrimary-67 {
  background-color: #17b978 !important;
}

.account_sidebarcollapseelementcontainer {
  padding: 5px;
  padding-left: 20px;
  margin-left: 45px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.4s ease-in;
  color: black;
}

.account_sidebarelementcontainer {
  padding: 5px;
  margin-left: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.4s ease-in;
  color: black;
}

.account_sidebarcompress .account_sidebarelementcontainer {
  margin-left: 5px;
}

.activeaccount {
  background-color: #061644;
  cursor: pointer !important;
  color: #17b978 !important;
}

.account_sidebarelementcontainer img {
  margin-right: 15px;
  margin-left: 10px;
  width: 20px;
}

.account_sidebarcompress img {
  margin-right: 15px;
  margin-left: 0px;
  width: 23px;
}

.account_sidebarelementcontainer p,
.account_sidebarcollapseelementcontainer p {
  margin-bottom: 0px !important;
  font-size: 17px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeaccount i,
.activeaccount p {
  color: #55aad3 !important;
}

.nav_customstyle {
  position: fixed !important;
  top: 0;
  width: calc(100% - 235px);
  /* width: 100%; */
  margin-left: 235px;
  padding: 4px !important;
  background-color: #f5f5f9 !important;
  z-index: 1000;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

/* .nav_customstyle {
  position: fixed !important;
  top: 0;
  width: 85%;
  left: 15%;
  padding: 4px !important;
  background-color: #f5f5f9 !important;
  z-index: 1000;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
} */

.nav_customstylecompress {
  background-color: #f5f5f9 !important;
  position: fixed !important;
  display: flex !important;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  top: 0px;
  left: auto;
  right: 0px;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.header_imagecontainer img {
  height: 35px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: #dee2de !important;
}

.commoncomponent_cardcss {
  margin: 74px 10px 10px 10px;
  transition: all 0.5s ease;
  padding: 15px;
}

.dashboardcomponent_cardcss {
  /* margin: 100px 10px 10px 10px; */
  margin-top: 76px;
  transition: all 0.5s ease;
}

.newlisting_headertabdesign {
  border: 1px solid #d5d5db;
  border-radius: 5px;
  padding: 23px 7px;
  margin: 20px 0;
}

.s_newlisting_headertabdesign {
  border-radius: 5px;
  padding: 23px 7px;
  margin: 20px 0;
}

.control_formcontrol .form-control {
  width: 60% !important;
}

.listpagesearch_button {
  background-color: #f0f2f5 !important;
  color: #212529 !important;
}

.listing_addbutton {
  background: #17b978 !important;
  font-size: 18px !important;
  display: flex !important;
  color: #fff !important;
  border: 1px solid #17b978 !important;
  justify-content: center !important;
}

.listing_addbutton_i {
  background: white;
  border-radius: 50%;
  color: #7de1c3;
  margin-right: 6px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 119px !important;
  }
}

/* .MuiTab-root {
  /* border-right: 1px solid !important; */
/* max-width: 150px !important; */
/* }  */

.option_text {
  text-align: right !important;
}

/* .above-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.above-scroller {
  /* border: 1px solid #ccc; */
  overflow-x: scroll;
  overflow-y: hidden;
  height: 8px;
  display: block;
}

.scroller {
  height: 20px;
}

/*==================================
NEW ISW DESIGN 
=====================================*/

.tab-yellow {
  background-color: #fdf4e0 !important;
  color: #dda730 !important;
}

.tab-blue {
  background-color: #e4f0fe;
  color: #52a2eb;
}

.tab-red {
  background-color: #fae3e5;
  color: #eb6e52;
}

.tab-cyan {
  background-color: #d2f6f5;
  color: #3dcfb5;
}

.tab-purple {
  background-color: #dfd4fc;
  color: #8153f3;
}

.tab-pink {
  background-color: #facfd2;
  color: #e35b64;
}

.tab-magenta {
  background-color: #fae3f9;
  color: #ea40a6;
}

.tab-green {
  background-color: #e9fae3;
  color: #58c731;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  letter-spacing: 0.02em;
}

ul {
  list-style: none;
}

.btn:hover {
  color: #149e66;
}

a {
  text-decoration: none;
}

a:hover {
  color: #149e66;
}

input,
select {
  height: 40px !important;
  background-color: #f5f5f9 !important;
  font-size: 14px !important;
  border: none;
  color: #6d7290 !important;
  border-radius: 0.25rem !important;
}

h2 {
  font-size: 20px;
  color: #071a52;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

header {
  background-color: #f5f5f9;
  padding: 10px 1.5rem;
}

header .menu-toggle-icon {
  padding: 5px;
  background: #eee;
  color: #fff;
  border-radius: 6px;
  margin-right: 20px;
  transition: all 0.3s;
  display: none;
  transform: rotateY(180deg);
}

.tabdisplay {
  display: none !important;
}

header .menu-toggle-icon:hover {
  background: #eee;
  transform: rotateY(180deg) scale(1.07);
}

.header-menu-toggle-icon {
  padding: 5px;
  background: #eee;
  color: #000;
  border-radius: 6px;
  margin-right: 20px;
  transition: all 0.3s;
  display: none;
}

.header-menu-toggle-icon:hover {
  background: #eee;
}

header .form-select {
  width: unset;
  border: none;
  color: #6d7290;
  font-size: 14px;
  width: 330px;
}

header .form-select.header-input {
  background-color: #fff;
}

header .header-right {
  display: flex;
  align-items: center;
}

header .profile-drop {
  margin-left: 2rem;
}

header .profile-drop .dropdown-menu {
  color: #6d7290;
  font-size: 12px;
}

.dropdown1 {
  background-color: #17b978;
  border-radius: 5px;
}

header .profile-drop .profile-drop-btn {
  border: none;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: transparent;
}

header .profile-drop .profile-drop-btn:hover,
header .profile-drop .profile-drop-btn:active,
header .profile-drop .profile-drop-btn:focus {
  background-color: #e9ebf2;
}

header .profile {
  display: flex;
  align-items: center;
}

.btn-success:hover {
  background-color: #fff !important;
  color: #17b978 !important;
}

header .profile .img-container {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
}

header .profile .img-container img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.page-head .page-title {
  font-size: 20px;
  color: #6d7290;
  font-weight: 200;
  margin-bottom: 1.5rem;
}

.page-head .page-title.bold {
  font-weight: 600;
}

.page-head .breadcrums {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.PrivateTabIndicator-colorPrimary-68 {
  color: #17b978 !important;
}

.page-head .breadcrums .page-title {
  margin-bottom: 0;
  margin-right: 30px;
}

.page-head .breadcrums .b-link {
  margin: 0 0.5rem;
  color: #b1b1ca;
  font-size: 14px;
}

.s_span {
  color: #b1b1ca;
}

.page-head .breadcrums .b-link.active {
  color: #6d7290;
}

.PrivateTabIndicator-colorPrimary-68 {
  background-color: #17b978 !important;
}

.page-head .breadcrums .b-link:hover {
  text-decoration: underline;
}

.page-head .back-btn {
  display: inline-block;
  background: #f5f5f9;
  color: #b1b1ca;
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s;
  cursor: pointer;
}

.page-head .back-btn:hover {
  background: #6d7290;
  color: #fff;
}

.short-info-tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.short-info-tabs li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: calc(12.5% - 20px);
  min-width: 90px;
  height: 140px;
  border-radius: 16px;
  margin: 0 20px 10px 0;
}

/* .short-info-tabs li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 140px;
  height: 140px;
  border-radius: 16px;
  margin: 0 20px 20px 0;
} */

.short-info-tabs p {
  margin: 0;
}

.short-info-tabs .label {
  font-size: 16px;
  max-width: 12ch;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.2em;
}

.short-info-tabs .count {
  font-size: 36px;
  font-weight: 100;
}

* {
  transition: all 0.5s;
}

/* .table{
  color: #ccc !important;
} */

.select-active {
  border: none;
  border-radius: 5px;
  width: 266px;
  color: #6d7290;
  outline: none;
}

.select-active:active,
.select-active:focus {
  border: 2px solid rgb(100, 174, 254);
}

.sidebar {
  background-color: #071a52;
  color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 235px;
  overflow-y: auto;
  transition: all 0.3s;
}

.sidebar_compress {
  background-color: #071a52;
  color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 0px;
  overflow-y: auto;
  transition: all 0.3s;
}

/* .sidebar {
  background-color: #071a52;
  color: #fff;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
  flex: 0.15;
} */

/* .sidebar_compress {
  flex: 0 1 !important;
  display: none;
} */

.sidebar .logo-contai5er {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.sidebar .sidebar-submenu {
  background-color: #071a52;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
}

.sidebar .nav-subtitle {
  color: #086972;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar .nav-brand a {
  color: #17b978;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.sidebar .nav.flex-column {
  flex-wrap: nowrap;
  overflow-y: auto;
  height: -webkit-fill-available;
  height: stretch;
  /* margin-top: 100px; */
  /* padding-bottom: 20px; */
  padding-right: 8px;
}

.sidebar .nav.flex-column::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
}

.sidebar .nav.flex-column::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar .nav.flex-column::-webkit-scrollbar-thumb {
  background-color: #05133b;
  border-radius: 7px;
}

.sidebar .nav-item .icon {
  margin-right: 1.5rem;
  margin-top: -2px;
}

.sidebar .nav-item .icon svg {
  fill: #17b978;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 18px;
  padding: 0.75rem 1.5rem;
  position: relative;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  background: #061644;
  color: #17b978;
}

.sidebar .nav-link .nav-icon {
  position: absolute;
  right: 0;
}

.right {
  margin-left: 235px;
}

.page-card_container {
  background-color: #e9ebf2;
  height: -webkit-fill-available;
  height: stretch;
  overflow-y: auto;
}

.page-card_container .card {
  border: none;
  /* height: 310px; */
  min-height: 310px;
  margin-bottom: 1.5rem;
}

.custom-size {
  height: 150px !important;
  min-height: 150px !important;
}

.custom-size2 {
  height: auto !important;
  min-height: auto !important;
}

.page-card_container .card-head {
  margin: 0.5rem 0;
}

.page-card_container .card-title {
  color: #071a52;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.page-card_container .card-body {
  padding-left: 0;
  padding-right: 0;
}

.page-card_container .btn {
  border-radius: 6px;
  font-weight: 600;
  line-height: 1.2em;
}

.page-card_container .btn:hover {
  color: #0d6efd;
}

.page-card_container a.dropdown-toggle:after {
  display: none;
}

.page-card_container .dropdown-menu {
  border: none;
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.24);
  font-size: 12px;
}

.page-card_container .dropdown-menu .dropdown-item {
  color: #6d7290;
}

.page-card_container .img-container {
  width: 100%;
  height: 210px;
  overflow: hidden;
}

.page-card_container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.page-card_container .list {
  height: 200px;
  /* overflow-y: scroll; */
}

.page-card_container .list-item {
  border-bottom: 1px solid #e9ebf2;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 10px;
  transition: all 0.3s;
  color: inherit;
}

.page-card_container .list-item:hover {
  transform: translateX(7px);
}

.page-card_container .list-item .item-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-card_container .list-item .item-title {
  color: #071a52;
  font-size: 14px;
  font-weight: bold;
}

.page-card_container .list-item .item-date {
  color: #b1b1ca;
  font-size: 12px;
}

.page-card_container .list-item .item-desc {
  color: #6d7290;
  font-size: 12px;
  margin-top: 5px;
}

.page-card_container .table-container {
  height: 200px;
  /* overflow-y: auto; */
  padding-right: 5px;
}

.page-card_container .card-table {
  color: #6d7290;
}

.page-card_container .card-table thead {
  background-color: #f5f5f9;
}

.page-card_container .card-table thead th:first-child {
  border-radius: 6px 0 0 6px;
}

.page-card_container .card-table thead th:last-child {
  text-align: right;
  border-radius: 0 6px 6px 0;
}

.page-card_container .card-table th {
  font-size: 12px;
}

.page-card_container .card-table th,
.page-card_container .card-table td {
  padding: 0.5rem 0.75rem;
}

.page-card_container .card-table tbody td {
  font-size: 12px;
}

.page-card_container .card-table tbody tr td:last-child {
  text-align: right;
  font-weight: 600;
}

.page-card_container .reminder-item .item-day {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f5f5f9;
  padding: 0 1rem;
  margin-right: 1rem;
}

.page-card_container .reminder-item .item-day p {
  margin: 0;
  padding: 0;
}

.page-card_container .reminder-item .date {
  font-size: 20px;
  line-height: 20px;
}

.page-card_container .reminder-item .day {
  font-size: 12px;
  color: #6d7290;
}

.page-card_container .reminder-item .item-date {
  margin: 0;
}

.gree {
  color: #17b978;
}

.lead-page .card {
  height: auto;
  min-height: auto;
}

.lead-page .lead-top .input-grp {
  margin-right: 1rem;
}

.lead-page .lead-top .input-grp label {
  margin-bottom: 0.5rem;
}

.lead-page .lead-top .input-grp.right-inp input,
.lead-page .lead-top .input-grp.right-inp select {
  min-width: 320px;
}

.lead-page .lead-top input,
.lead-page .lead-top select {
  min-width: 100px;
}

.lead-page input,
.lead-page select {
  background-color: #f5f5f9;
  font-size: 14px;
  border: none;
  color: #6d7290;
}

.lead-page .page-index {
  color: #6d7290;
}

.lead-page .lead-table-card button {
  background-color: #17b978;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  padding: 0.75rem 1rem;
}

.lead-page .lead-table-card .card-table thead {
  background-color: transparent;
  text-transform: uppercase;
  vertical-align: text-top;
}

.lead-page .lead-table-card .card-table td p {
  margin: 0;
  padding: 0;
}

.lead-page .lead-table-card .card-table tbody tr td:last-child {
  text-align: inherit;
  font-weight: 400;
}

.lead-page .lead-table-card .card-table thead th,
.lead-page .lead-table-card .card-table tbody td {
  padding-left: 0;
}

.lead-page .table-foot p {
  color: #6d7290;
}

.pagination {
  border: none;
}

.pagination .page-item .page-link,
.pagination .page-item:first-child .page-link {
  border: none;
}

.pagination .page-item .page-link {
  border-radius: 4px;
  color: #6d7290;
  font-weight: 600;
  margin: 0 0.25rem;
}

.pagination .page-item.active .page-link {
  background-color: #17b978;
  color: #fff;
}

.page-card_container .lead-top form label {
  font-size: 14px;
  font-weight: 600;
  color: #071a52;
}

.page-card_container .lead-top form span {
  color: #ea40a6;
}

.page-card_container .lead-top form .textarea {
  background-color: #f5f5f9;
  font-size: 14px;
  border: none;
  color: #6d7290;
}

.page-card_container .reset-btn {
  border: 1px solid #17b978;
  color: #17b978;
  font-weight: 600;
}

.page-card_container .reset-btn:hover {
  background-color: #17b978;
  color: #fff;
}

.page-card_container .btn {
  float: right;
  /* padding: 10px 20px; */
  text-decoration: none;
}

.page-card_container .btn:hover {
  color: #fff;
}

.page-card_container .submit-btn {
  background-color: #17b978;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.page-card_container .submit-btn:hover {
  background-color: #149e66;
  color: #fff;
}

.page-card_container .button-container .resume-btn {
  border: 1px solid #17b978;
  color: #17b978;
  font-weight: 600;
  float: left;
}

.page-card_container .button-container .resume-btn:hover {
  background-color: #17b978;
  color: #fff;
}

.page-card_container textarea {
  resize: none;
}

.page-card_container .check-details .clock label {
  color: #6d7290;
  font-size: 20px;
  font-weight: 600;
}

.page-card_container .check-details .clock p {
  color: #6d7290;
  font-size: 16px;
}

.page-card_container .check-details .check-in-details {
  float: left;
}

.page-card_container .check-details .check-in-details label {
  color: #6d7290;
  font-size: 16px;
  font-weight: 600;
}

.page-card_container .check-details .check-in-details p {
  color: #6d7290;
  font-size: 14px;
}

.page-card_container .button .resume-btn {
  float: right;
}

.link a {
  color: #6d7290;
}

.link a:hover {
  color: #17b978;
}

.button1 .resume-btn {
  float: right;
}

.green {
  color: #17b978;
}

.lead-table-card {
  max-height: 600px;
  overflow-y: scroll;
}

.lead-table-card button.colorchange {
  background-color: #6d7290;
}

.lead-table-card .sticky-top {
  top: -25px;
}

.over {
  background-color: #fff;
  overflow-y: auto;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
}

.content-wrapper .card.client-profile {
  /* width: 302px; */
  min-width: 265px;
  padding: 0px 20px;
  position: relative;
}

.content-wrapper .card.client-profile .options {
  position: absolute;
  top: -15px;
  right: 0;
}

.content-wrapper .card.client-profile .options .options-content {
  position: absolute;
  top: 60px;
  left: -110px;
  background: #fff;
  min-width: 90px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.04);
  transform: translateY(-10px);
  visibility: hidden;
  opacity: 0;
}

.content-wrapper .card.client-profile .options .options-content ul {
  padding: 0;
  margin: 0;
  border-radius: 6px;
}

.content-wrapper .card.client-profile .options .options-content a {
  padding: 10px;
  display: block;
  color: inherit;
}

.content-wrapper .card.client-profile .options .options-content a:hover {
  color: #17b978;
}

.content-wrapper .card.client-profile .options input.checkbox:checked~.options-content {
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
}

.content-wrapper .card.client-profile .options input.checkbox {
  visibility: hidden;
  height: 0;
  width: 0;
}

.content-wrapper .card.client-profile .options .icon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 10px;
}

.content-wrapper .card.client-profile .client-img {
  text-align: center;
  margin-top: 10px;
}

.content-wrapper .card.client-profile .client-img img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
}

.content-wrapper .card.client-profile .client-img h2 {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin: 24px auto;
}

.content-wrapper .card.client-profile .client-img .status {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.content-wrapper .card.client-profile .client-img .status img {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}


.content-wrapper .card.client-profile .provider-img {
  text-align: center;
  margin-top: 10px;
}

.content-wrapper .card.client-profile .provider-img img {
  height: 130px;
  width: 130px;
}

.content-wrapper .card.client-profile .provider-img h2 {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin: 24px auto;
}

.content-wrapper .card.client-profile .provider-img .status {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.content-wrapper .card.client-profile .provider-img .status img {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}

.content-wrapper .card.client-profile .personal-details h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.content-wrapper .card.client-profile .personal-details div {
  margin-bottom: 20px;
}

.content-wrapper .card.client-profile .personal-details div p {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.content-wrapper .card.client-profile .personal-details div p span {
  color: #eb6e52;
}

.content-wrapper .card.client-details {
  width: 100%;
  margin-left: 20px;
  padding: 0px 30px;
}

.content-wrapper .card.client-details .nav {
  border-bottom: 1px solid #e9ebf2;
}

.content-wrapper .card.client-details .nav.inner-tab-menu {
  position: relative;
}

.content-wrapper .card.client-details .nav .nav-link {
  font-size: 16px;
  /* border-radius: 0; */
  /* padding: 0 0 5px; */
  margin-right: 20px;
  color: #6d7290;
  font-weight: bold;
}

.content-wrapper .card.client-details .nav .nav-link.active {
  background: transparent;
  color: #17b978 !important;
  border-bottom: 2px solid #17b978;
}

.content-wrapper .card.client-details .nav .buttons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.content-wrapper .card.client-details .nav .buttons a {
  padding: 10px 20px;
  border: 1px solid #17b978;
  color: #17b978;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.3s;
}

.content-wrapper .card.client-details .nav .buttons a:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .nav .buttons a:last-child {
  background: #17b978;
  color: #fff;
  margin-left: 16px;
}

.content-wrapper .card.client-details .nav .buttons a:last-child:hover {
  border: 1px solid #17b978;
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .notes {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.content-wrapper .card.client-details .notes .note-card {
  width: 100%;
  max-width: 310px;
  padding: 20px;
  border: 1px solid #eee;
  margin: 10px;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title {
  display: flex;
  justify-content: space-between;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options {
  position: relative;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .icon {
  cursor: pointer;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .note-checkbox {
  position: absolute;
  visibility: hidden;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content {
  position: absolute;
  top: 30px;
  left: -80px;
  background: #fff;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  min-width: 90px;
  transform: translateY(-10px);
  visibility: hidden;
  opacity: 0;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content ul {
  padding: 0;
  margin: 0;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content a {
  display: block;
  padding: 10px;
  color: inherit;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content a:hover {
  color: #17b978;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options input.note-checkbox:checked~.options-content {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.content-wrapper .card.client-details .notes .note-card p {
  font-size: 12px;
  line-height: 22px;
  color: #6d7290;
  margin: 10px 0;
}

.content-wrapper .card.client-details .notes .note-card p.info {
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .notes .note-card p span {
  padding: 4px 5px;
  background: #a7ff83;
  color: #086972;
  border-radius: 2px;
  margin-right: 10px;
  text-align: center;
}

.content-wrapper .card.client-details .client-notes-table,
.content-wrapper .card.client-details .client-documents-table {
  padding-top: 10px;
}

.content-wrapper .card.client-details .client-notes-table table td,
.content-wrapper .card.client-details .client-notes-table table th,
.content-wrapper .card.client-details .client-documents-table table td,
.content-wrapper .card.client-details .client-documents-table table th {
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.content-wrapper .card.client-details .client-notes-table table th,
.content-wrapper .card.client-details .client-documents-table table th {
  font-weight: 600;
}

.content-wrapper .card.client-details .client-notes-table table .button,
.content-wrapper .card.client-details .client-documents-table table .button {
  padding: 9px 13px;
  font-size: 14px;
  color: #6d7290;
  background: #eee;
  border-radius: 6px;
  transition: all 0.3s;
}

.content-wrapper .card.client-details .client-notes-table table .button:first-child,
.content-wrapper .card.client-details .client-documents-table table .button:first-child {
  margin-right: 10px;
}

.content-wrapper .card.client-details .client-notes-table table .button:hover,
.content-wrapper .card.client-details .client-documents-table table .button:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .wrapper {
  display: flex;
  align-items: center;
}

.content-wrapper .card.client-details .wrapper .img-box {
  margin-right: 36px;
}

.content-wrapper .card.client-details .wrapper .content h3 {
  font-size: 20px;
  line-height: 25px;
  color: #071a52;
}

.content-wrapper .card.client-details .wrapper .content p {
  color: #6d7290;
  font-size: 14px;
}

.content-wrapper .card.client-details .client-documents-table table td:nth-child(3) {
  width: 110px;
}

.content-wrapper .card.client-details .client-documents-table table td:nth-child(4) {
  max-width: 130px;
}

.content-wrapper .card.client-details .admission .buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .admission .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .admission .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .admission .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .admission .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .admission .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .admission .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .admission .client-notes-table .table {
  width: 120%;
}

.content-wrapper .card.client-details .migration .buttons {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
}

.content-wrapper .card.client-details .migration .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .migration .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .migration .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .migration .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .migration .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .migration .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .migration .client-notes-table .table {
  width: 120%;
}

.content-wrapper .card.client-details .insurance .buttons {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
}

.content-wrapper .card.client-details .insurance .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .insurance .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .insurance .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .insurance .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .insurance .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .insurance .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .insurance .client-notes-table .table {
  width: 120%;
}

.content-wrapper .card.client-details .skill-assessment .buttons {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
}

.content-wrapper .card.client-details .skill-assessment .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .skill-assessment .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .skill-assessment .client-notes-table .table {
  width: 140%;
}

.content-wrapper .card.client-details .accounts {
  padding-top: 14px;
}

.content-wrapper .card.client-details .accounts .buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .accounts .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .accounts .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .accounts .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .accounts .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .accounts .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .notes {
  padding-top: 14px;
}

.content-wrapper .card.client-details .notes .buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .notes .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .notes .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .notes .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .notes .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .notes .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.lead-page .lead-top input.check1,
.lead-page .lead-top input.check2 {
  height: 20px;
  min-width: 20px;
  width: 20px;
  margin-right: 10px;
}

.form-check.checkbox-group {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 5px 0;
}

@media screen and (max-width: 1300px) {
  .content-wrapper .card.client-details .nav .nav-link {
    font-size: 14px;
  }

  .content-wrapper .card.client-details .notes .note-card {
    max-width: 285px;
  }

  .content-wrapper .card.client-details .client-notes-table {
    overflow-x: scroll;
  }

  .page-card_container .card-table {
    width: 120%;
  }

  .card.client-documents-table {
    overflow-x: scroll;
  }

  .content-wrapper .card.client-details .admission .client-notes-table .table {
    width: 150%;
  }

  .content-wrapper .card.client-details .insurance .client-notes-table .table {
    width: 130%;
  }

  .content-wrapper .card.client-details .skill-assessment .client-notes-table .table {
    width: 170%;
  }
}

.workload tbody,
.workload td,
.workload tfoot,
.workload thead,
.workload tr {
  border-style: none;
  color: #6d7290;
}

.workload th {
  color: #071a52;
  font-size: 14px;
}

.workload .color {
  color: #17b978;
}

.workload a {
  color: #6d7290;
}

.workload button.small {
  font-size: 14px;
  padding: 12px 25px;
}

.page-card_container .card-table thead th:last-child {
  text-align: center;
}

.login-page {
  background-color: #071a52;
  height: 100vh;
  width: 100%;
}

.login-page .form-container {
  background-color: #fff;
  border-radius: 6px;
  padding: 36px;
  width: 800px;
}

.logo-container {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.login-page .logo-container {
  margin-bottom: 1rem;
}

.login-page .form-head {
  margin-bottom: 2.75rem;
}

.login-page .form-title {
  color: #071a52;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.login-page .form-desc {
  color: #6d7290;
  font-weight: 200;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.login-page .input-grp {
  margin-bottom: 1.5rem;
  position: relative;
}

.login-page .input-grp .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.login-page .button-container {
  padding-bottom: 50px;
  border-bottom: 1px solid #e9ebf2;
  margin-bottom: 1.75rem;
}

.login-page .login-btn {
  background-color: #17b978;
  color: #fff;
  font-weight: 600;
  width: 185px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

.login-page .login-btn:hover {
  background-color: #149e66;
}

.login-page .forget-password-link {
  color: #17b978;
  font-weight: 600;
  font-size: 16px;
}

.login-page .forget-password-link:hover {
  color: #149e66;
}

.login-page input.form-control {
  color: #6d7290;
  font-size: 14px;
  padding-left: 40px;
  height: 50px;
}

.forget-pw-page .form-container {
  width: 470px;
}

.forget-pw-page .form-head {
  margin-bottom: 2rem;
}

.vertical ::-webkit-scrollbar {
  width: 3px;
}

.vertical ::-webkit-scrollbar-track {
  background: #f5f5f9;
}

.vertical ::-webkit-scrollbar-thumb {
  background: #e9ebf2;
}

.vertical ::-webkit-scrollbar-thumb:hover {
  background: #343a40;
}

/*# sourceMappingURL=main.css.map */

.MuiPaginationItem-page.Mui-selected {
  background-color: #17b978 !important;
  color: white;
}

.select__control .css-z1jeuj-control .select__value-container .select__value-container--is-multi .css-g1d714-ValueContainer {
  height: 16px !important;
}

.breadcrums-bar {
  padding: 15px 30px 5px 25px !important;
}

.makeStyles-selectdrop-4 {
  height: 45px !important;
}

.css-g1d714-ValueContainer {
  height: 43px !important;
  /* background: #F5F5F9 !important; */
}

/* .css-1hb7zxy-IndicatorsContainer {
  background: #F5F5F9 !important;
} */

.MuiSvgIcon-root {
  font-size: "2.5rem" !important;
}

.MuiTableHead-root {
  background-color: #e9ebf2 !important;
}

.tab-limegreen {
  background-color: #17ba7933 !important;
  color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-49 {
  background-color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-76 {
  background-color: #17b978 !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-left: 10px !important;
}

.btn-success {
  color: #fff !important;
  background-color: #17b978 !important;
  border-color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-84 {
  background-color: #17b978 !important;
}

.greeneffect {
  cursor: pointer;
}

.greeneffect:hover {
  /* color:#0c5e3d; */
  color: #149e66 !important;
}

.grayeffect {
  cursor: pointer;
  color: #6d7290 !important;
}

.grayeffect:hover {
  /* color:#0c5e3d; */
  color: #149e66 !important;
}

.effects {
  cursor: pointer;
}

.effects:hover {
  color: #17b978;
}

.table td {
  padding: 10px 20px;
  vertical-align: middle;
  font-size: 14px;
  background-color: transparent;
}

.listing_addbutton:hover {
  color: #17b978 !important;
  background-color: #fff !important;
  border: 1px solid #17b978 !important;
}

.btn-secondary {
  background-color: #fff !important;
  border-color: #17b978 !important;
  color: #17b978 !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #17b978 !important;
  border-color: #17b978 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-65 {
  background-color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-61 {
  background-color: #17b978 !important;
}

.MuiAppBar-colorDefault {
  background-color: #fff !important;
}

/* .w-50{
    width: 100% !important;
  } */

.sent-button {
  background: #17b978 !important;
  color: #fff !important;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid #17b978;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
}

.sent-button:hover {
  background: #fff !important;
  color: #17b978 !important;
  border: 1px solid #17b978;
  cursor: pointer;
}

.MuiTypography-body1 {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.02em !important;
  min-width: 130px !important;
  font-weight: 600 !important;
  color: #6d7290 !important;
}

.MuiTypography-body2 {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.02em !important;
  font-weight: 600 !important;
  color: #071a52 !important;
}

.workload tr:hover {
  background: #eee;
}

.outline-button {
  background: transparent;
  color: #17b978;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid #17b978;
  display: inline-block;
  font-weight: bold;
}

.outline-button:hover {
  color: #fff;
  background-color: #17b978;
  border: 1px solid #17b978;
}

.role-deletebtn {
  padding: 9px 13px;
  font-size: 14px;
  color: #6d7290;
  background: #eee;
  border-radius: 6px;
  transition: all 0.3s;
  border: 1px solid #eee;
  display: inline-block;
}

.role-deletebtn:hover {
  color: #fff !important;
  background-color: #17b978;
  border: 1px solid #17b978;
}

.nav-tabs .nav-link.active {
  /* border: none !important; */
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-link.active:hover {
  border: none !important;
}

.unpaid-button {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid crimson;
  color: crimson;
  border-radius: 6px;
}

.paddingDashboard {
  padding: 0 !important;
  padding-right: 24px !important;
}

.PrivateTabIndicator-colorPrimary-75 {
  background-color: #17b978 !important;
}

.align_placeholder::placeholder {
  text-align: right !important;
}

.align_placeholder:-ms-input-placeholder {
  text-align: right !important;
}

.align_placeholder::-webkit-input-placeholder {
  text-align: right !important;
}

/* Nitish css changes */
.labelHead {
  color: #6d7290;
  margin-bottom: 0;
  margin-left: 1rem;
  text-transform: capitalize;
}

.labelHead strong {
  margin-left: 0.25rem;
}

.nav-header {
  width: 100%;
  display: flex;
  padding: 10px 24px;
  justify-content: space-between;
  height: 60px;
}

.nav-select {
  width: 330px;
  height: 38px;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}

.nav-username {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.card-body {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 1.25rem;
  margin-top: 10px;
  max-height: 310px;
  overflow: auto;
  padding: 0 !important;
}

.card-body-sangam {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 1.25rem;
  margin-top: 10px;
  max-height: 210px;
  overflow: auto;
  padding: 0 !important;
}

.card-body-san {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 1.25rem;
  margin-top: 10px;
  max-height: 210px;
  overflow: auto;
  padding: 0 !important;
}

.MuiPopover-paper {
  margin-top: 50px !important;
  width: 170px !important;
}

/* .page-head {
  margin-top: 5rem;
}; */
.table-responsive {
  max-height: 480px;
  overflow: auto;
}

.makeStyles-selectlimit-5 {
  /* min-width: 100px !important;
  text-align: left !important;
  margin-right: 0px !important; */
  min-width: 65px;
  text-align: center;
  margin-right: 15px;
}

@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    margin-right: 0.3%;
  }
}

.width_css {
  width: 100%;
}

.s_label_color {
  color: #212529 !important;
  margin-bottom: 0rem;
  display: inline-block;
}

.select-currency {
  width: 100%;
}

.payableinvoicestatus i {
  /* position: absolute;
  right: 21px;
  top: 7px; */
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 100%;
}

.payableinvoicestatus i:hover {
  background: #ccc;
}

a:hover {
  cursor: pointer;
}

.resp-row {
  /* flex-direction: column; */
  grid-gap: 6px;
  gap: 6px;
}

.workflowdashboard_documentmodal canvas {
  width: 100%;
  height: 100%;
}

.react-checkbox-tree {
  margin-top: 14px;
}

.table th,
.table td {
  padding: 0.30rem !important;
}

.s_table .table th,
.s_table .table td {
  padding: 0.50rem !important;
}

.ck-placeholder {
  display: none;
}

.ReactModal__Overlay {
  z-index: 3;
}

.sangam_html h1 {
  font-size: 15px !important;
  margin-top: 50px !important;
}
