.mainnote {
  /* width: 800px; */
  width: 100%;
}
.notes {
  display: flex;
  justify-content: space-between;
}
.notes1 {
  display: flex;
  flex: 0.4;
  margin: 5px;
}
.appointment1 {
  display: flex;
  flex: 0.7;
  margin: 5px;
}
.attach1 {
  flex: 0.2;
  margin: 5px;
}
.attach2 {
  flex: 0.8;
  display: flex;
  margin: 5px;
}
.notes12 {
  padding: 5px;
  flex: 0.2;
}
.notes13 {
  padding: 5px;
  flex: 0.6;
}
.notes2 {
  display: flex;
  flex: 0.6;
  overflow: hidden;
  margin: 5px;
}
.appointment2 {
  display: flex;
  flex: 0.3;
  overflow: hidden;
  margin: 5px;
}
.notes21 {
  padding: 5px;
  flex: 0.6;
}
.notes22 {
  padding: 5px;
  flex: 0.3;
}
.appointment22 {
  padding: 5px;
  flex: 1;
}
.hr {
  margin-top: 0 !important;
}
.hr2 {
  margin-bottom: 0 !important;
}
.notes_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notes_appointment {
  display: flex;
  flex: 0.6;
  justify-content: space-evenly;
}
.notes_appointment2 {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
}
.attachment_name {
  padding: 5px;
  flex: 0.4;
}
