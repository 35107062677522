.accordion_name {
  display: flex;
}

.accordion_namebutton {
  flex: 0.1;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.accordion_radio {
  display: flex;
  background-color: rgba(245, 245, 249, 0.03);
}

.accordion_radio1 {
  flex: 0.1;
}

.dummy3_accordion {
  flex: 0.8;
}

@media only screen and (max-width: 800px) {
  .dummy3_accordion {
    flex: 0.7;
  }
}

@media only screen and (max-width: 400px) {
  .dummy3_accordion {
    flex: 0.5;
  }
}

.accordion_radio2 {
  flex: 1.9;
  background-color: none;
}

.MuiAccordionDetails-root {
  display: block;
}
.MuiPaper-elevation4{
  box-shadow: none !important;
}
.accordiondetails {
  margin-left: 30px;
  margin-right: 15px;
}
.summary{
  border: 1px solid #E9EBF2;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 30px;
}

.logs {
  display: flex;
  justify-content: space-between;
}

.log_item1 {
  flex: 0.8;
}

.log_item2 {
  flex: 0.2;
}

.cancel_margin {
  margin-left: 20px;
}

.positioning {
  position: relative;
  top: 15px;
}

.color {
  color: green;
}

.step_complete_css {
  /* margin-top: 10px !important; */
  /* margin-bottom: 10px !important; */
  background: #eee !important;
  color: #6d7290 !important;
  padding: 9px 13px !important;
  font-size: 14px !important;
  margin-left: 10px !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  border: none !important;
}