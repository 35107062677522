body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  /* height: 12px;
  z-index: 0;
  width: 10px; */
  height: 8px;
  z-index: 0;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  /* background:#ccc; */
  border-radius: 10px;
  width: 5px;
  /* background: #f5f5f9;
  border-radius: 10px; */
}

::-webkit-scrollbar-thumb:hover {
  /* background:#ccc;
  border-radius: 10px;
  width: 5px; */
  background: #071A52;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  /* background: #d1c7ff33; */
  background: #e9ebf2;
}

/*Sangam Changes in ClientUser.js Page */

.newlisting_headertabdesignone {
  padding: 1.5rem;
  border-right: 1.5rem solid #e9ebf2;
  border-left: 1.5rem solid #e9ebf2;
  border-top: 1.5rem solid #e9ebf2;
  margin-bottom: 0;
}

.s_maindiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -17px;
}

.s_submaster {
  margin-top: -10px;
}

.s_select {
  border: none;
  padding-top: 5px;
  background-color: #f5f5f9;
  height: 45px;
}

.s_form-control {
  background-color: #f5f5f9;
  width: -webkit-fill-available;
}

.datepickerrangevaluetext {
  background-color: #f5f5f9;
}

.datepickerrangevaluetextone {
  background-color: #f5f5f9;
  padding: 10px;
  color: #6d7290;
  height: 40px;
}

.s_search {
  margin-top: 12px;
}

.newlisting_headertabdesigntwo {
  padding: 1.5rem;
  border: 1.5rem solid #e9ebf2;
  margin-top: 0;
  margin-bottom: 0;
}

.s_para {
  margin-top: 15px;
  color: #6D7290;
  margin-left: 10px;
}

.s_tr {
  color: #6d7290;
}

.s_newdiv {
  display: flex;
  margin: 10px 0;
  justify-content: flex-end;
}

.s_dropname {
  display: flex;
}

.s_danger {
  float: right;
  margin-right: 10px;
}

.head-breadcrum {
  margin-top: 20px;
}

/*Sangam Changes in ViewEnquiryWorkflowList.js Page */
.lead-page {
  margin-top: 50px;
}

.page-head {
  margin-top: 5rem;
}

.s_float {
  float: right;
}

.s_cursor {
  cursor: pointer;
}

.s_pagi {
  margin-top: -20px;
  margin-bottom: 20px;
}

.s_spanPage {
  margin-left: 90px;
  margin-top: -32px;
  color: #6d7290
}

.card.client-profile {
  min-width: 265px;
}

.closei {
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: 700px;
}

.s_upload {
  font-size: 20px;
  font-weight: bold;
  color: #530684;
}

.s_choosefile {
  color: #8B919D;
  font-size: 12px;
  line-height: 18px;
}

.s_inactive {
  background-color: #FF9999;
  color: #6d7290;
  ;
}

.primary-button {
  background: #17b978;
  color: #fff;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
  display: inline-block;
}

.primary-button:hover {
  background: transparent;
  color: #17b978;
}

.secondary-button {
  padding: 9px 13px;
  font-size: 14px;
  color: #6d7290;
  background: #eee;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #eee;
  display: inline-block;
}

.secondary-button:hover {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.progress-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 10px;
}

.progress-wrapper .progress {
  height: 3px;
}

.progress-wrapper p {
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  color: #6d7290;
}

.receipt {
  border-radius: 6px;
  border: 1px solid #eee;
  margin-bottom: 30px;
}

.receipt .top-header {
  --bs-gutter-x: 0 !important;
  background: #6d7290;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 20px;
  color: #fff;
}

.receipt .top-header h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.receipt .top-header p {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}

.receipt .content {
  padding: 20px;
}

.receipt .content h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #6d7290;
}

.receipt .content p {
  color: #071a52;
  font-weight: 600;
}

.summary {
  border: 1px solid #E9EBF2;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px;
}

.summary h3 {
  font-size: 20px;
  font-weight: 600;
}

.summary ul {
  margin: 0;
  padding: 0;
}

.summary ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  color: #071a52;
  margin-bottom: 14px;
}

.summary ul li.green {
  color: #086972;
}

.summary ul li:last-child {
  margin-bottom: 0;
}

.summary ul li strong {
  font-weight: 700;
}

.profile-drop .dropdown-menu {
  color: black;
  font-size: 12px;
}

.s_client_limit {
  min-width: 72.63px;
  text-align: center;
}

.s_client_setting {
  min-width: 96.63px;
  text-align: center;
}

.s_client_datepickerrangevaluetextone {
  background-color: #f5f5f9;
  padding: 10px;
  color: #6d7290;
  height: 40px;
  min-width: 120.94px;
}

.s_client_form-control {
  background-color: #f5f5f9;
  width: 169.25px;
}

.s_client_form-controlone {
  background-color: #f5f5f9;
  width: 120.94px;
}

.s_sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.s_admission_form-control {
  background-color: #f5f5f9;
  width: 207.25px;
}

.form-check .checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 5px 0;
}

.info-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  margin: 10px auto;
}

.info-group label {
  min-width: 130px;
  font-weight: 600;
  color: #6d7290 !important;
}

.info-group span {
  font-weight: 600;
  color: #071a52;
}

.info-group span.box {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #e9ebf2;
  border-radius: 6px;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.info-group h5 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid crimson;
  color: crimson;
  border-radius: 6px;
}

.info-group h5.paid {
  color: #17b978;
  border: 2px solid #17b978;
}

.other-quotation-edit .info-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  margin: 10px auto;
}

.other-quotation-edit .info-group label {
  min-width: 130px;
  font-weight: 600;
  color: #6d7290;
}

.other-quotation-edit .info-group span {
  font-weight: 600;
  color: #071a52;
}

.other-quotation-edit .info-group span.box {
  padding: 10px;
  margin-left: 10px;
  border: 1px solid #e9ebf2;
  border-radius: 6px;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.discount label {
  color: #071a52;
  font-weight: 600;
}

.discount .total-box {
  display: inline-block;
  padding: 10px;
  border: 1px dashed #333;
  border-radius: 6px;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin: 5px;
}

.discount .total-box span {
  font-size: 20px;
}

.discount .total-box span.value {
  font-size: 50px;
  color: #071a52;
}

.check .form-check .form-check-input {
  min-width: 0;
}

.form-check.checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 5px 0;
}

/* .s_checkbox {
  background-color: #f5f9f5;
  font-size: 14px;
  border: none;
  color: #6d7290;
} */

/* .input.s_checkbox{
  background-color: pink;
  font-size: 14px;
  border: none;
  color: #6d7290;
} */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #f5f5f9;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #0d6efd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}

/* 
2nd check box
 */


.containerone {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containerone input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkone {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerone:hover input~.checkmarkone {
  background-color: #f5f5f9;
}


.containerone input:checked~.checkmarkone {
  background-color: #0d6efd;
}

.checkmarkone:after {
  content: "";
  position: absolute;
  display: none;
}


.containerone input:checked~.checkmarkone:after {
  display: block;
}


.containerone .checkmarkone:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}

/* 
3rd check box
 */


.containerthree {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containerthree input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkthree {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerthree:hover input~.checkmarkthree {
  background-color: #f5f5f9;
}


.containerthree input:checked~.checkmarkthree {
  background-color: #0d6efd;
}

.checkmarkthree:after {
  content: "";
  position: absolute;
  display: none;
}


.containerthree input:checked~.checkmarkthree:after {
  display: block;
}


.containerthree .checkmarkthree:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}



/* 
4th check box
 */


.containerfour {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containerfour input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkfour {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerfour:hover input~.checkmarkfour {
  background-color: #f5f5f9;
}


.containerfour input:checked~.checkmarkfour {
  background-color: #0d6efd;
}

.checkmarkfour:after {
  content: "";
  position: absolute;
  display: none;
}


.containerfour input:checked~.checkmarkfour:after {
  display: block;
}


.containerfour .checkmarkfour:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}


/* 
5th check box
 */


.containerfive {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containerfive input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkfive {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerfive:hover input~.checkmarkfive {
  background-color: #f5f5f9;
}


.containerfive input:checked~.checkmarkfive {
  background-color: #0d6efd;
}

.checkmarkfive:after {
  content: "";
  position: absolute;
  display: none;
}


.containerfive input:checked~.checkmarkfive:after {
  display: block;
}


.containerfive .checkmarkfive:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}


/* 
6th check box
 */


.containersix {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containersix input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarksix {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containersix:hover input~.checkmarksix {
  background-color: #f5f5f9;
}


.containersix input:checked~.checkmarksix {
  background-color: #0d6efd;
}

.checkmarksix:after {
  content: "";
  position: absolute;
  display: none;
}


.containersix input:checked~.checkmarksix:after {
  display: block;
}


.containersix .checkmarksix:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}


/* 
7th check box
 */


.containerseven {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containerseven input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkseven {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containerseven:hover input~.checkmarkseven {
  background-color: #f5f5f9;
}


.containerseven input:checked~.checkmarkseven {
  background-color: #0d6efd;
}

.checkmarkseven:after {
  content: "";
  position: absolute;
  display: none;
}


.containerseven input:checked~.checkmarkseven:after {
  display: block;
}


.containerseven .checkmarkseven:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}

/* 
8th check box
 */


.containereight {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.containereight input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmarkeight {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f5f5f9;
  border-radius: 0.25em;
}


.containereight:hover input~.checkmarkeight {
  background-color: #f5f5f9;
}


.containereight input:checked~.checkmarkeight {
  background-color: #0d6efd;
}

.checkmarkeight:after {
  content: "";
  position: absolute;
  display: none;
}


.containereight input:checked~.checkmarkeight:after {
  display: block;
}


.containereight .checkmarkeight:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}

.s_tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #17b978;
}

.s_tooltip .s_tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: #17b978;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.s_tooltip:hover .s_tooltiptext {
  visibility: visible;
  margin-top: 35px;
  margin-left: -85px;
}

.sun-editor-editable {
  line-height: 1.2 !important;
}

.file-viewer-style {
  border: 10px solid green;
  width: 100%;
  height: 100%;
}

.my-apple {
  font-family: sans-serif;
  text-align: center;
  border: 10px solid green;
  width: 100%;
  height: 100%;
  display: "flex";
  object-fit: "contain";
  overflow: "hidden";
  padding-left: "120px";
}

.my-apple canvas {
  width: 75% !important;
  height: 100% !important;
}

/* .ulclass li {
  
} */

.ulclass li p {
  /* padding: 0px; */
  padding: 5px 16px 5px 16px;
}

.ulclass li :hover {
  background: #e9ebf2;
}

.aclass {
  text-decoration: auto !important;
  color: currentColor !important;
}

.static-table tbody tr:nth-child(odd) {
  background: #eee;
}

.static-table tbody tr:nth-child(even) {
  background: #fff;
}

.styles-module_tooltip__mnnfp {
  visibility: hidden;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  will-change: opacity, visibility;
}

.s_ckeditor .ck-editor__main div {
  height: 485px !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  height: 485px !important;
}

.s_horiline {
  width: 140%;
  height: 15px;
  background-color: #e9ebf2;
  margin-left: -8%;
}