* {
  font-family: "Source Sans Pro", sans-serif;
  /* font-size: 14px; */
  letter-spacing: 0.02em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.discount_type input {
  display: inline-block;
  height: 10px !important;
}

.header_dropdown {
  height: 38px !important;
  width: 100%;
  background: white !important;
}

.css-5duvv3-control {
  height: 38px !important;
  width: 100%;
  border: none !important;
}

.css-g1d714-ValueContainer {
  height: 38px !important;
  width: 100%;
}

.header_dropdown .css-yk16xz-control {
  height: 38px !important;
  border-width: 0px !important;
}

.header_dropdown .css-1hwfws3 {
  height: 38px !important;
  padding-top: 2px !important;
}

.header_dropdownone {
  height: 30px !important;
}

.header_dropdownone .css-yk16xz-control {
  height: 30px !important;
  border-width: 0px !important;
}

.header_dropdownone .css-1hwfws3 {
  height: 30px !important;
  padding-top: 2px !important;
}

.brach_dropdown {
  height: 40px !important;
  width: 100%;
  background-color: #f5f5f9 !important;
}

.brach_dropdown .css-yk16xz-control {
  height: 40px !important;
  border-width: 0px !important;
  background-color: #f5f5f9 !important;
}

.brach_dropdown .css-1hwfws3 {
  height: 40px !important;
  padding-top: 2px !important;
  background-color: #f5f5f9 !important;
}

.report_dropdown {
  height: 40px !important;
  width: 95%;
  background-color: #f5f5f9 !important;
}

.report_dropdown .css-yk16xz-control {
  height: 40px !important;
  border-width: 0px !important;
  background-color: #f5f5f9 !important;
}

.report_dropdown .css-1hwfws3 {
  height: 40px !important;
  padding-top: 2px !important;
  background-color: #f5f5f9 !important;
}

.ins_dropdown {
  height: 40px !important;
  width: 100%;
  background-color: #f5f5f9 !important;
}

.ins_dropdown .css-yk16xz-control {
  height: 40px !important;
  border-width: 0px !important;
  background-color: #f5f5f9 !important;
}

.ins_dropdown .css-1hwfws3 {
  height: 40px !important;
  padding-top: 2px !important;
  background-color: #f5f5f9 !important;
}

/* .header_dropdownone{
  height: 30px !important;
}
.header_dropdownone .css-yk16xz-control {
  height: 30px !important;
  border-width: 0px !important;
}
.header_dropdownone .css-1hwfws3 {
  height: 30px !important;
  padding-top: 2px !important;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8;
}

.fp-container .fp-loader {
  top: 30%;
  left: 40%;
  z-index: 1000;
  position: absolute;
}

.PrivateTabIndicator-colorPrimary-70 {
  background-color: #17b978 !important;
}

.MuiButton-outlinedPrimary {
  color: #17b978 !important;
  border: 1px solid #17b978 !important;
}

.account_sidebar {
  background-color: #55aad3;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  flex: 0.15;
  transition: all 0.4s ease-in-out;
}

.account_sidebarcompress {
  background-color: #55aad3;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  flex: 0.03 1;
  overflow-x: hidden;
  transition: all 0.4s ease-in-out;
}

.account_sidebar::-webkit-scrollbar,
.account_sidebarcompress::-webkit-scrollbar {
  height: 10px;
  z-index: 0;
  width: 8px;
}

.tab-bold {
  font-weight: bold !important;
  font-size: 16px !important;
}

.account_sidebar::-webkit-scrollbar-thumb,
.account_sidebarcompress::-webkit-scrollbar-thumb {
  background: #55aad3;
  border-radius: 10px;
}

.account_sidebar::-webkit-scrollbar-track,
.account_sidebarcompress::-webkit-scrollbar-track {
  background: #d1c7ff33;
}

.account_sidebarimgcontainer {
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.account_sidebarimgcontainer img {
  cursor: pointer;
  height: 60px;
}

.sorting {
  /* display: block; */
  content: " ";
  /* position: absolute; */
  top: 50%;
  right: 0;
  /* -webkit-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  background-size: 12px 12px;
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin-left: 2px;
  color: #071a52;
}

.PrivateTabIndicator-colorPrimary-67 {
  background-color: #17b978 !important;
}

.account_sidebarcollapseelementcontainer {
  padding: 5px;
  padding-left: 20px;
  margin-left: 45px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.4s ease-in;
  color: black;
}

.account_sidebarelementcontainer {
  padding: 5px;
  margin-left: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.4s ease-in;
  color: black;
}

.account_sidebarcompress .account_sidebarelementcontainer {
  margin-left: 5px;
}

.activeaccount {
  background-color: #061644;
  cursor: pointer !important;
  color: #17b978 !important;
}

.account_sidebarelementcontainer img {
  margin-right: 15px;
  margin-left: 10px;
  width: 20px;
}

.account_sidebarcompress img {
  margin-right: 15px;
  margin-left: 0px;
  width: 23px;
}

.account_sidebarelementcontainer p,
.account_sidebarcollapseelementcontainer p {
  margin-bottom: 0px !important;
  font-size: 17px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeaccount i,
.activeaccount p {
  color: #55aad3 !important;
}

.nav_customstyle {
  position: fixed !important;
  top: 0;
  width: calc(100% - 235px);
  /* width: 100%; */
  margin-left: 235px;
  padding: 4px !important;
  background-color: #f5f5f9 !important;
  z-index: 1000;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

/* .nav_customstyle {
  position: fixed !important;
  top: 0;
  width: 85%;
  left: 15%;
  padding: 4px !important;
  background-color: #f5f5f9 !important;
  z-index: 1000;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
} */

.nav_customstylecompress {
  background-color: #f5f5f9 !important;
  position: fixed !important;
  display: flex !important;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  top: 0px;
  left: auto;
  right: 0px;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.header_imagecontainer img {
  height: 35px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}

.table-striped tbody tr:nth-of-type(odd):hover {
  background-color: #dee2de !important;
}

.commoncomponent_cardcss {
  margin: 74px 10px 10px 10px;
  transition: all 0.5s ease;
  padding: 15px;
}

.dashboardcomponent_cardcss {
  /* margin: 100px 10px 10px 10px; */
  margin-top: 76px;
  transition: all 0.5s ease;
}

.newlisting_headertabdesign {
  border: 1px solid #d5d5db;
  border-radius: 5px;
  padding: 23px 7px;
  margin: 20px 0;
}

.s_newlisting_headertabdesign {
  border-radius: 5px;
  padding: 23px 7px;
  margin: 20px 0;
}

.control_formcontrol .form-control {
  width: 60% !important;
}

.listpagesearch_button {
  background-color: #f0f2f5 !important;
  color: #212529 !important;
}

.listing_addbutton {
  background: #17b978 !important;
  font-size: 18px !important;
  display: flex !important;
  color: #fff !important;
  border: 1px solid #17b978 !important;
  justify-content: center !important;
}

.listing_addbutton_i {
  background: white;
  border-radius: 50%;
  color: #7de1c3;
  margin-right: 6px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 119px !important;
  }
}

/* .MuiTab-root {
  /* border-right: 1px solid !important; */
/* max-width: 150px !important; */
/* }  */

.option_text {
  text-align: right !important;
}

/* .above-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.above-scroller {
  /* border: 1px solid #ccc; */
  overflow-x: scroll;
  overflow-y: hidden;
  height: 8px;
  display: block;
}

.scroller {
  height: 20px;
}

/*==================================
NEW ISW DESIGN 
=====================================*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600&display=swap");

.tab-yellow {
  background-color: #fdf4e0 !important;
  color: #dda730 !important;
}

.tab-blue {
  background-color: #e4f0fe;
  color: #52a2eb;
}

.tab-red {
  background-color: #fae3e5;
  color: #eb6e52;
}

.tab-cyan {
  background-color: #d2f6f5;
  color: #3dcfb5;
}

.tab-purple {
  background-color: #dfd4fc;
  color: #8153f3;
}

.tab-pink {
  background-color: #facfd2;
  color: #e35b64;
}

.tab-magenta {
  background-color: #fae3f9;
  color: #ea40a6;
}

.tab-green {
  background-color: #e9fae3;
  color: #58c731;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  letter-spacing: 0.02em;
}

ul {
  list-style: none;
}

.btn:hover {
  color: #149e66;
}

a {
  text-decoration: none;
}

a:hover {
  color: #149e66;
}

input,
select {
  height: 40px !important;
  background-color: #f5f5f9 !important;
  font-size: 14px !important;
  border: none;
  color: #6d7290 !important;
  border-radius: 0.25rem !important;
}

h2 {
  font-size: 20px;
  color: #071a52;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

header {
  background-color: #f5f5f9;
  padding: 10px 1.5rem;
}

header .menu-toggle-icon {
  padding: 5px;
  background: #eee;
  color: #fff;
  border-radius: 6px;
  margin-right: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.tabdisplay {
  display: none !important;
}

header .menu-toggle-icon:hover {
  background: #eee;
  -webkit-transform: rotateY(180deg) scale(1.07);
  transform: rotateY(180deg) scale(1.07);
}

.header-menu-toggle-icon {
  padding: 5px;
  background: #eee;
  color: #000;
  border-radius: 6px;
  margin-right: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}

.header-menu-toggle-icon:hover {
  background: #eee;
}

header .form-select {
  width: unset;
  border: none;
  color: #6d7290;
  font-size: 14px;
  width: 330px;
}

header .form-select.header-input {
  background-color: #fff;
}

header .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header .profile-drop {
  margin-left: 2rem;
}

header .profile-drop .dropdown-menu {
  color: #6d7290;
  font-size: 12px;
}

.dropdown1 {
  background-color: #17b978;
  border-radius: 5px;
}

header .profile-drop .profile-drop-btn {
  border: none;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: transparent;
}

header .profile-drop .profile-drop-btn:hover,
header .profile-drop .profile-drop-btn:active,
header .profile-drop .profile-drop-btn:focus {
  background-color: #e9ebf2;
}

header .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-success:hover {
  background-color: #fff !important;
  color: #17b978 !important;
}

header .profile .img-container {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
}

header .profile .img-container img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.page-head .page-title {
  font-size: 20px;
  color: #6d7290;
  font-weight: 200;
  margin-bottom: 1.5rem;
}

.page-head .page-title.bold {
  font-weight: 600;
}

.page-head .breadcrums {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.PrivateTabIndicator-colorPrimary-68 {
  color: #17b978 !important;
}

.page-head .breadcrums .page-title {
  margin-bottom: 0;
  margin-right: 30px;
}

.page-head .breadcrums .b-link {
  margin: 0 0.5rem;
  color: #b1b1ca;
  font-size: 14px;
}

.s_span {
  color: #b1b1ca;
}

.page-head .breadcrums .b-link.active {
  color: #6d7290;
}

.PrivateTabIndicator-colorPrimary-68 {
  background-color: #17b978 !important;
}

.page-head .breadcrums .b-link:hover {
  text-decoration: underline;
}

.page-head .back-btn {
  display: inline-block;
  background: #f5f5f9;
  color: #b1b1ca;
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.page-head .back-btn:hover {
  background: #6d7290;
  color: #fff;
}

.short-info-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.short-info-tabs li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: calc(12.5% - 20px);
  min-width: 90px;
  height: 140px;
  border-radius: 16px;
  margin: 0 20px 10px 0;
}

/* .short-info-tabs li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 140px;
  height: 140px;
  border-radius: 16px;
  margin: 0 20px 20px 0;
} */

.short-info-tabs p {
  margin: 0;
}

.short-info-tabs .label {
  font-size: 16px;
  max-width: 12ch;
  text-align: center;
  text-transform: capitalize;
  line-height: 1.2em;
}

.short-info-tabs .count {
  font-size: 36px;
  font-weight: 100;
}

* {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* .table{
  color: #ccc !important;
} */

.select-active {
  border: none;
  border-radius: 5px;
  width: 266px;
  color: #6d7290;
  outline: none;
}

.select-active:active,
.select-active:focus {
  border: 2px solid rgb(100, 174, 254);
}

.sidebar {
  background-color: #071a52;
  color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 235px;
  overflow-y: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar_compress {
  background-color: #071a52;
  color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 0px;
  overflow-y: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* .sidebar {
  background-color: #071a52;
  color: #fff;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
  flex: 0.15;
} */

/* .sidebar_compress {
  flex: 0 1 !important;
  display: none;
} */

.sidebar .logo-contai5er {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.sidebar .sidebar-submenu {
  background-color: #071a52;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
}

.sidebar .nav-subtitle {
  color: #086972;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar .nav-brand a {
  color: #17b978;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.sidebar .nav.flex-column {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-y: auto;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  /* margin-top: 100px; */
  /* padding-bottom: 20px; */
  padding-right: 8px;
}

.sidebar .nav.flex-column::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
}

.sidebar .nav.flex-column::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar .nav.flex-column::-webkit-scrollbar-thumb {
  background-color: #05133b;
  border-radius: 7px;
}

.sidebar .nav-item .icon {
  margin-right: 1.5rem;
  margin-top: -2px;
}

.sidebar .nav-item .icon svg {
  fill: #17b978;
}

.sidebar .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 18px;
  padding: 0.75rem 1.5rem;
  position: relative;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  background: #061644;
  color: #17b978;
}

.sidebar .nav-link .nav-icon {
  position: absolute;
  right: 0;
}

.right {
  margin-left: 235px;
}

.page-card_container {
  background-color: #e9ebf2;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  overflow-y: auto;
}

.page-card_container .card {
  border: none;
  /* height: 310px; */
  min-height: 310px;
  margin-bottom: 1.5rem;
}

.custom-size {
  height: 150px !important;
  min-height: 150px !important;
}

.custom-size2 {
  height: auto !important;
  min-height: auto !important;
}

.page-card_container .card-head {
  margin: 0.5rem 0;
}

.page-card_container .card-title {
  color: #071a52;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.page-card_container .card-body {
  padding-left: 0;
  padding-right: 0;
}

.page-card_container .btn {
  border-radius: 6px;
  font-weight: 600;
  line-height: 1.2em;
}

.page-card_container .btn:hover {
  color: #0d6efd;
}

.page-card_container a.dropdown-toggle:after {
  display: none;
}

.page-card_container .dropdown-menu {
  border: none;
  -webkit-box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.24);
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.24);
  font-size: 12px;
}

.page-card_container .dropdown-menu .dropdown-item {
  color: #6d7290;
}

.page-card_container .img-container {
  width: 100%;
  height: 210px;
  overflow: hidden;
}

.page-card_container .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.page-card_container .list {
  height: 200px;
  /* overflow-y: scroll; */
}

.page-card_container .list-item {
  border-bottom: 1px solid #e9ebf2;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
}

.page-card_container .list-item:hover {
  -webkit-transform: translateX(7px);
  transform: translateX(7px);
}

.page-card_container .list-item .item-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-card_container .list-item .item-title {
  color: #071a52;
  font-size: 14px;
  font-weight: bold;
}

.page-card_container .list-item .item-date {
  color: #b1b1ca;
  font-size: 12px;
}

.page-card_container .list-item .item-desc {
  color: #6d7290;
  font-size: 12px;
  margin-top: 5px;
}

.page-card_container .table-container {
  height: 200px;
  /* overflow-y: auto; */
  padding-right: 5px;
}

.page-card_container .card-table {
  color: #6d7290;
}

.page-card_container .card-table thead {
  background-color: #f5f5f9;
}

.page-card_container .card-table thead th:first-child {
  border-radius: 6px 0 0 6px;
}

.page-card_container .card-table thead th:last-child {
  text-align: right;
  border-radius: 0 6px 6px 0;
}

.page-card_container .card-table th {
  font-size: 12px;
}

.page-card_container .card-table th,
.page-card_container .card-table td {
  padding: 0.5rem 0.75rem;
}

.page-card_container .card-table tbody td {
  font-size: 12px;
}

.page-card_container .card-table tbody tr td:last-child {
  text-align: right;
  font-weight: 600;
}

.page-card_container .reminder-item .item-day {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #f5f5f9;
  padding: 0 1rem;
  margin-right: 1rem;
}

.page-card_container .reminder-item .item-day p {
  margin: 0;
  padding: 0;
}

.page-card_container .reminder-item .date {
  font-size: 20px;
  line-height: 20px;
}

.page-card_container .reminder-item .day {
  font-size: 12px;
  color: #6d7290;
}

.page-card_container .reminder-item .item-date {
  margin: 0;
}

.gree {
  color: #17b978;
}

.lead-page .card {
  height: auto;
  min-height: auto;
}

.lead-page .lead-top .input-grp {
  margin-right: 1rem;
}

.lead-page .lead-top .input-grp label {
  margin-bottom: 0.5rem;
}

.lead-page .lead-top .input-grp.right-inp input,
.lead-page .lead-top .input-grp.right-inp select {
  min-width: 320px;
}

.lead-page .lead-top input,
.lead-page .lead-top select {
  min-width: 100px;
}

.lead-page input,
.lead-page select {
  background-color: #f5f5f9;
  font-size: 14px;
  border: none;
  color: #6d7290;
}

.lead-page .page-index {
  color: #6d7290;
}

.lead-page .lead-table-card button {
  background-color: #17b978;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  padding: 0.75rem 1rem;
}

.lead-page .lead-table-card .card-table thead {
  background-color: transparent;
  text-transform: uppercase;
  vertical-align: text-top;
}

.lead-page .lead-table-card .card-table td p {
  margin: 0;
  padding: 0;
}

.lead-page .lead-table-card .card-table tbody tr td:last-child {
  text-align: inherit;
  font-weight: 400;
}

.lead-page .lead-table-card .card-table thead th,
.lead-page .lead-table-card .card-table tbody td {
  padding-left: 0;
}

.lead-page .table-foot p {
  color: #6d7290;
}

.pagination {
  border: none;
}

.pagination .page-item .page-link,
.pagination .page-item:first-child .page-link {
  border: none;
}

.pagination .page-item .page-link {
  border-radius: 4px;
  color: #6d7290;
  font-weight: 600;
  margin: 0 0.25rem;
}

.pagination .page-item.active .page-link {
  background-color: #17b978;
  color: #fff;
}

.page-card_container .lead-top form label {
  font-size: 14px;
  font-weight: 600;
  color: #071a52;
}

.page-card_container .lead-top form span {
  color: #ea40a6;
}

.page-card_container .lead-top form .textarea {
  background-color: #f5f5f9;
  font-size: 14px;
  border: none;
  color: #6d7290;
}

.page-card_container .reset-btn {
  border: 1px solid #17b978;
  color: #17b978;
  font-weight: 600;
}

.page-card_container .reset-btn:hover {
  background-color: #17b978;
  color: #fff;
}

.page-card_container .btn {
  float: right;
  /* padding: 10px 20px; */
  text-decoration: none;
}

.page-card_container .btn:hover {
  color: #fff;
}

.page-card_container .submit-btn {
  background-color: #17b978;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.page-card_container .submit-btn:hover {
  background-color: #149e66;
  color: #fff;
}

.page-card_container .button-container .resume-btn {
  border: 1px solid #17b978;
  color: #17b978;
  font-weight: 600;
  float: left;
}

.page-card_container .button-container .resume-btn:hover {
  background-color: #17b978;
  color: #fff;
}

.page-card_container textarea {
  resize: none;
}

.page-card_container .check-details .clock label {
  color: #6d7290;
  font-size: 20px;
  font-weight: 600;
}

.page-card_container .check-details .clock p {
  color: #6d7290;
  font-size: 16px;
}

.page-card_container .check-details .check-in-details {
  float: left;
}

.page-card_container .check-details .check-in-details label {
  color: #6d7290;
  font-size: 16px;
  font-weight: 600;
}

.page-card_container .check-details .check-in-details p {
  color: #6d7290;
  font-size: 14px;
}

.page-card_container .button .resume-btn {
  float: right;
}

.link a {
  color: #6d7290;
}

.link a:hover {
  color: #17b978;
}

.button1 .resume-btn {
  float: right;
}

.green {
  color: #17b978;
}

.lead-table-card {
  max-height: 600px;
  overflow-y: scroll;
}

.lead-table-card button.colorchange {
  background-color: #6d7290;
}

.lead-table-card .sticky-top {
  top: -25px;
}

.over {
  background-color: #fff;
  overflow-y: auto;
}

.content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-wrapper .card.client-profile {
  /* width: 302px; */
  min-width: 265px;
  padding: 0px 20px;
  position: relative;
}

.content-wrapper .card.client-profile .options {
  position: absolute;
  top: -15px;
  right: 0;
}

.content-wrapper .card.client-profile .options .options-content {
  position: absolute;
  top: 60px;
  left: -110px;
  background: #fff;
  min-width: 90px;
  -webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.04);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  visibility: hidden;
  opacity: 0;
}

.content-wrapper .card.client-profile .options .options-content ul {
  padding: 0;
  margin: 0;
  border-radius: 6px;
}

.content-wrapper .card.client-profile .options .options-content a {
  padding: 10px;
  display: block;
  color: inherit;
}

.content-wrapper .card.client-profile .options .options-content a:hover {
  color: #17b978;
}

.content-wrapper .card.client-profile .options input.checkbox:checked~.options-content {
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.content-wrapper .card.client-profile .options input.checkbox {
  visibility: hidden;
  height: 0;
  width: 0;
}

.content-wrapper .card.client-profile .options .icon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 10px;
}

.content-wrapper .card.client-profile .client-img {
  text-align: center;
  margin-top: 10px;
}

.content-wrapper .card.client-profile .client-img img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
}

.content-wrapper .card.client-profile .client-img h2 {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin: 24px auto;
}

.content-wrapper .card.client-profile .client-img .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.content-wrapper .card.client-profile .client-img .status img {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}


.content-wrapper .card.client-profile .provider-img {
  text-align: center;
  margin-top: 10px;
}

.content-wrapper .card.client-profile .provider-img img {
  height: 130px;
  width: 130px;
}

.content-wrapper .card.client-profile .provider-img h2 {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin: 24px auto;
}

.content-wrapper .card.client-profile .provider-img .status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.content-wrapper .card.client-profile .provider-img .status img {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}

.content-wrapper .card.client-profile .personal-details h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.content-wrapper .card.client-profile .personal-details div {
  margin-bottom: 20px;
}

.content-wrapper .card.client-profile .personal-details div p {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.content-wrapper .card.client-profile .personal-details div p span {
  color: #eb6e52;
}

.content-wrapper .card.client-details {
  width: 100%;
  margin-left: 20px;
  padding: 0px 30px;
}

.content-wrapper .card.client-details .nav {
  border-bottom: 1px solid #e9ebf2;
}

.content-wrapper .card.client-details .nav.inner-tab-menu {
  position: relative;
}

.content-wrapper .card.client-details .nav .nav-link {
  font-size: 16px;
  /* border-radius: 0; */
  /* padding: 0 0 5px; */
  margin-right: 20px;
  color: #6d7290;
  font-weight: bold;
}

.content-wrapper .card.client-details .nav .nav-link.active {
  background: transparent;
  color: #17b978 !important;
  border-bottom: 2px solid #17b978;
}

.content-wrapper .card.client-details .nav .buttons {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.content-wrapper .card.client-details .nav .buttons a {
  padding: 10px 20px;
  border: 1px solid #17b978;
  color: #17b978;
  border-radius: 6px;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.content-wrapper .card.client-details .nav .buttons a:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .nav .buttons a:last-child {
  background: #17b978;
  color: #fff;
  margin-left: 16px;
}

.content-wrapper .card.client-details .nav .buttons a:last-child:hover {
  border: 1px solid #17b978;
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .notes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.content-wrapper .card.client-details .notes .note-card {
  width: 100%;
  max-width: 310px;
  padding: 20px;
  border: 1px solid #eee;
  margin: 10px;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options {
  position: relative;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .icon {
  cursor: pointer;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .note-checkbox {
  position: absolute;
  visibility: hidden;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content {
  position: absolute;
  top: 30px;
  left: -80px;
  background: #fff;
  -webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  min-width: 90px;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  visibility: hidden;
  opacity: 0;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content ul {
  padding: 0;
  margin: 0;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content a {
  display: block;
  padding: 10px;
  color: inherit;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options .options-content a:hover {
  color: #17b978;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title .options input.note-checkbox:checked~.options-content {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.content-wrapper .card.client-details .notes .note-card .note-card-title h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.content-wrapper .card.client-details .notes .note-card p {
  font-size: 12px;
  line-height: 22px;
  color: #6d7290;
  margin: 10px 0;
}

.content-wrapper .card.client-details .notes .note-card p.info {
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .notes .note-card p span {
  padding: 4px 5px;
  background: #a7ff83;
  color: #086972;
  border-radius: 2px;
  margin-right: 10px;
  text-align: center;
}

.content-wrapper .card.client-details .client-notes-table,
.content-wrapper .card.client-details .client-documents-table {
  padding-top: 10px;
}

.content-wrapper .card.client-details .client-notes-table table td,
.content-wrapper .card.client-details .client-notes-table table th,
.content-wrapper .card.client-details .client-documents-table table td,
.content-wrapper .card.client-details .client-documents-table table th {
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.content-wrapper .card.client-details .client-notes-table table th,
.content-wrapper .card.client-details .client-documents-table table th {
  font-weight: 600;
}

.content-wrapper .card.client-details .client-notes-table table .button,
.content-wrapper .card.client-details .client-documents-table table .button {
  padding: 9px 13px;
  font-size: 14px;
  color: #6d7290;
  background: #eee;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.content-wrapper .card.client-details .client-notes-table table .button:first-child,
.content-wrapper .card.client-details .client-documents-table table .button:first-child {
  margin-right: 10px;
}

.content-wrapper .card.client-details .client-notes-table table .button:hover,
.content-wrapper .card.client-details .client-documents-table table .button:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-wrapper .card.client-details .wrapper .img-box {
  margin-right: 36px;
}

.content-wrapper .card.client-details .wrapper .content h3 {
  font-size: 20px;
  line-height: 25px;
  color: #071a52;
}

.content-wrapper .card.client-details .wrapper .content p {
  color: #6d7290;
  font-size: 14px;
}

.content-wrapper .card.client-details .client-documents-table table td:nth-child(3) {
  width: 110px;
}

.content-wrapper .card.client-details .client-documents-table table td:nth-child(4) {
  max-width: 130px;
}

.content-wrapper .card.client-details .admission .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .admission .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .admission .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .admission .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .admission .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .admission .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .admission .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .admission .client-notes-table .table {
  width: 120%;
}

.content-wrapper .card.client-details .migration .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 30px 0;
}

.content-wrapper .card.client-details .migration .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .migration .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .migration .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .migration .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .migration .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .migration .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .migration .client-notes-table .table {
  width: 120%;
}

.content-wrapper .card.client-details .insurance .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 30px 0;
}

.content-wrapper .card.client-details .insurance .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .insurance .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .insurance .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .insurance .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .insurance .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .insurance .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .insurance .client-notes-table .table {
  width: 120%;
}

.content-wrapper .card.client-details .skill-assessment .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 30px 0;
}

.content-wrapper .card.client-details .skill-assessment .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .skill-assessment .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .skill-assessment .client-notes-table {
  overflow-x: scroll;
}

.content-wrapper .card.client-details .skill-assessment .client-notes-table .table {
  width: 140%;
}

.content-wrapper .card.client-details .accounts {
  padding-top: 14px;
}

.content-wrapper .card.client-details .accounts .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .accounts .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .accounts .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .accounts .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .accounts .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .accounts .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.content-wrapper .card.client-details .notes {
  padding-top: 14px;
}

.content-wrapper .card.client-details .notes .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.content-wrapper .card.client-details .notes .buttons a {
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.content-wrapper .card.client-details .notes .buttons a.button-outline {
  border: 1px solid #17b978;
  color: #17b978;
}

.content-wrapper .card.client-details .notes .buttons a.button-outline:hover {
  background: #17b978;
  color: #fff;
}

.content-wrapper .card.client-details .notes .buttons a.button {
  background: #17b978;
  color: #fff;
  border: 1px solid #17b978;
}

.content-wrapper .card.client-details .notes .buttons a.button:hover {
  background: transparent;
  color: #17b978;
}

.lead-page .lead-top input.check1,
.lead-page .lead-top input.check2 {
  height: 20px;
  min-width: 20px;
  width: 20px;
  margin-right: 10px;
}

.form-check.checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 5px 0;
}

@media screen and (max-width: 1300px) {
  .content-wrapper .card.client-details .nav .nav-link {
    font-size: 14px;
  }

  .content-wrapper .card.client-details .notes .note-card {
    max-width: 285px;
  }

  .content-wrapper .card.client-details .client-notes-table {
    overflow-x: scroll;
  }

  .page-card_container .card-table {
    width: 120%;
  }

  .card.client-documents-table {
    overflow-x: scroll;
  }

  .content-wrapper .card.client-details .admission .client-notes-table .table {
    width: 150%;
  }

  .content-wrapper .card.client-details .insurance .client-notes-table .table {
    width: 130%;
  }

  .content-wrapper .card.client-details .skill-assessment .client-notes-table .table {
    width: 170%;
  }
}

.workload tbody,
.workload td,
.workload tfoot,
.workload thead,
.workload tr {
  border-style: none;
  color: #6d7290;
}

.workload th {
  color: #071a52;
  font-size: 14px;
}

.workload .color {
  color: #17b978;
}

.workload a {
  color: #6d7290;
}

.workload button.small {
  font-size: 14px;
  padding: 12px 25px;
}

.page-card_container .card-table thead th:last-child {
  text-align: center;
}

.login-page {
  background-color: #071a52;
  height: 100vh;
  width: 100%;
}

.login-page .form-container {
  background-color: #fff;
  border-radius: 6px;
  padding: 36px;
  width: 800px;
}

.logo-container {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.login-page .logo-container {
  margin-bottom: 1rem;
}

.login-page .form-head {
  margin-bottom: 2.75rem;
}

.login-page .form-title {
  color: #071a52;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.login-page .form-desc {
  color: #6d7290;
  font-weight: 200;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.login-page .input-grp {
  margin-bottom: 1.5rem;
  position: relative;
}

.login-page .input-grp .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.login-page .button-container {
  padding-bottom: 50px;
  border-bottom: 1px solid #e9ebf2;
  margin-bottom: 1.75rem;
}

.login-page .login-btn {
  background-color: #17b978;
  color: #fff;
  font-weight: 600;
  width: 185px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

.login-page .login-btn:hover {
  background-color: #149e66;
}

.login-page .forget-password-link {
  color: #17b978;
  font-weight: 600;
  font-size: 16px;
}

.login-page .forget-password-link:hover {
  color: #149e66;
}

.login-page input.form-control {
  color: #6d7290;
  font-size: 14px;
  padding-left: 40px;
  height: 50px;
}

.forget-pw-page .form-container {
  width: 470px;
}

.forget-pw-page .form-head {
  margin-bottom: 2rem;
}

.vertical ::-webkit-scrollbar {
  width: 3px;
}

.vertical ::-webkit-scrollbar-track {
  background: #f5f5f9;
}

.vertical ::-webkit-scrollbar-thumb {
  background: #e9ebf2;
}

.vertical ::-webkit-scrollbar-thumb:hover {
  background: #343a40;
}

/*# sourceMappingURL=main.css.map */

.MuiPaginationItem-page.Mui-selected {
  background-color: #17b978 !important;
  color: white;
}

.select__control .css-z1jeuj-control .select__value-container .select__value-container--is-multi .css-g1d714-ValueContainer {
  height: 16px !important;
}

.breadcrums-bar {
  padding: 15px 30px 5px 25px !important;
}

.makeStyles-selectdrop-4 {
  height: 45px !important;
}

.css-g1d714-ValueContainer {
  height: 43px !important;
  /* background: #F5F5F9 !important; */
}

/* .css-1hb7zxy-IndicatorsContainer {
  background: #F5F5F9 !important;
} */

.MuiSvgIcon-root {
  font-size: "2.5rem" !important;
}

.MuiTableHead-root {
  background-color: #e9ebf2 !important;
}

.tab-limegreen {
  background-color: #17ba7933 !important;
  color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-49 {
  background-color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-76 {
  background-color: #17b978 !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-left: 10px !important;
}

.btn-success {
  color: #fff !important;
  background-color: #17b978 !important;
  border-color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-84 {
  background-color: #17b978 !important;
}

.greeneffect {
  cursor: pointer;
}

.greeneffect:hover {
  /* color:#0c5e3d; */
  color: #149e66 !important;
}

.grayeffect {
  cursor: pointer;
  color: #6d7290 !important;
}

.grayeffect:hover {
  /* color:#0c5e3d; */
  color: #149e66 !important;
}

.effects {
  cursor: pointer;
}

.effects:hover {
  color: #17b978;
}

.table td {
  padding: 10px 20px;
  vertical-align: middle;
  font-size: 14px;
  background-color: transparent;
}

.listing_addbutton:hover {
  color: #17b978 !important;
  background-color: #fff !important;
  border: 1px solid #17b978 !important;
}

.btn-secondary {
  background-color: #fff !important;
  border-color: #17b978 !important;
  color: #17b978 !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #17b978 !important;
  border-color: #17b978 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-65 {
  background-color: #17b978 !important;
}

.PrivateTabIndicator-colorPrimary-61 {
  background-color: #17b978 !important;
}

.MuiAppBar-colorDefault {
  background-color: #fff !important;
}

/* .w-50{
    width: 100% !important;
  } */

.sent-button {
  background: #17b978 !important;
  color: #fff !important;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
}

.sent-button:hover {
  background: #fff !important;
  color: #17b978 !important;
  border: 1px solid #17b978;
  cursor: pointer;
}

.MuiTypography-body1 {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.02em !important;
  min-width: 130px !important;
  font-weight: 600 !important;
  color: #6d7290 !important;
}

.MuiTypography-body2 {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.02em !important;
  font-weight: 600 !important;
  color: #071a52 !important;
}

.workload tr:hover {
  background: #eee;
}

.outline-button {
  background: transparent;
  color: #17b978;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #17b978;
  display: inline-block;
  font-weight: bold;
}

.outline-button:hover {
  color: #fff;
  background-color: #17b978;
  border: 1px solid #17b978;
}

.role-deletebtn {
  padding: 9px 13px;
  font-size: 14px;
  color: #6d7290;
  background: #eee;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #eee;
  display: inline-block;
}

.role-deletebtn:hover {
  color: #fff !important;
  background-color: #17b978;
  border: 1px solid #17b978;
}

.nav-tabs .nav-link.active {
  /* border: none !important; */
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-link.active:hover {
  border: none !important;
}

.unpaid-button {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid crimson;
  color: crimson;
  border-radius: 6px;
}

.paddingDashboard {
  padding: 0 !important;
  padding-right: 24px !important;
}

.PrivateTabIndicator-colorPrimary-75 {
  background-color: #17b978 !important;
}

.align_placeholder::placeholder {
  text-align: right !important;
}

.align_placeholder:-ms-input-placeholder {
  text-align: right !important;
}

.align_placeholder::-webkit-input-placeholder {
  text-align: right !important;
}

/* Nitish css changes */
.labelHead {
  color: #6d7290;
  margin-bottom: 0;
  margin-left: 1rem;
  text-transform: capitalize;
}

.labelHead strong {
  margin-left: 0.25rem;
}

.nav-header {
  width: 100%;
  display: flex;
  padding: 10px 24px;
  justify-content: space-between;
  height: 60px;
}

.nav-select {
  width: 330px;
  height: 38px;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}

.nav-username {
  display: flex;
  align-items: center;
  gap: 20px;
}

.card-body {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 1.25rem;
  margin-top: 10px;
  max-height: 310px;
  overflow: auto;
  padding: 0 !important;
}

.card-body-sangam {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 1.25rem;
  margin-top: 10px;
  max-height: 210px;
  overflow: auto;
  padding: 0 !important;
}

.card-body-san {
  flex: 1 1 auto;
  /* min-height: 1px; */
  padding: 1.25rem;
  margin-top: 10px;
  max-height: 210px;
  overflow: auto;
  padding: 0 !important;
}

.MuiPopover-paper {
  margin-top: 50px !important;
  width: 170px !important;
}

/* .page-head {
  margin-top: 5rem;
}; */
.table-responsive {
  max-height: 480px;
  overflow: auto;
}

.makeStyles-selectlimit-5 {
  /* min-width: 100px !important;
  text-align: left !important;
  margin-right: 0px !important; */
  min-width: 65px;
  text-align: center;
  margin-right: 15px;
}

@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    margin-right: 0.3%;
  }
}

.width_css {
  width: 100%;
}

.s_label_color {
  color: #212529 !important;
  margin-bottom: 0rem;
  display: inline-block;
}

.select-currency {
  width: 100%;
}

.payableinvoicestatus i {
  /* position: absolute;
  right: 21px;
  top: 7px; */
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 100%;
}

.payableinvoicestatus i:hover {
  background: #ccc;
}

a:hover {
  cursor: pointer;
}

.resp-row {
  /* flex-direction: column; */
  gap: 6px;
}

.workflowdashboard_documentmodal canvas {
  width: 100%;
  height: 100%;
}

.react-checkbox-tree {
  margin-top: 14px;
}

.table th,
.table td {
  padding: 0.30rem !important;
}

.s_table .table th,
.s_table .table td {
  padding: 0.50rem !important;
}

.ck-placeholder {
  display: none;
}

.ReactModal__Overlay {
  z-index: 3;
}

.sangam_html h1 {
  font-size: 15px !important;
  margin-top: 50px !important;
}