.dummy2 {
  margin-top: 15px;
  margin-left: 10px;
}
.dummy2_header {
  display: flex;
  justify-content: space-between;
}
.dummy2_typography {
  display: flex;
  padding: 10px;
  margin-right: 10px !important;
}
.dummy2_coursecontents {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.dummy2_coursecontent {
  flex: 0.33;
}
.dummy2_middle {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
@media only screen and (max-width: 1000px) {
  .dummy2_middle {
    flex-direction: column;
  }
}
#dummy2_middlecontent {
  flex: 0.7;
  margin: 5px;
}
.dummy2_middlecontent2 {
  margin: 5px;
  /* width: 270px; */
  /* flex: 0.3; */
}
.dummy2_productfee {
  display: flex;
  justify-content: space-between;
}
