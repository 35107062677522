/*body {
    margin: 0px;
    padding: 20px;
    font-family: sans-serif;
    background-color: #cccccc;
  }
  
  */
.example {
  border-left: 1px solid #999999;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #999999;
  border-radius: 10px;
  /* margin: 0px; */
  padding: 20px;
  margin-left: 20px;
}

.item {
  border-top: 1px solid #ffffff;
  background-color: #f2f2f2;
  border-bottom: 1px solid #999999;
  border-radius: 6px;
  margin-bottom: 4px;
  padding: 10px;
  padding-left: 8px;
  opacity: 1;
  /* new added */
  /* width: 162px; */
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: left;
  cursor: move;
  height: 50px;
  /* width: 150px; */
  /* color: black; */
  /* background-color: #ccc; */
  /* padding: 5px; */
  border-bottom: 1px solid #ddd;
  border-radius: 5px;
  border: 2px solid #666666;
}

.dragindicator {
  float: left;
}

.item .title {
  font-weight: bold;
  font-size: 125%;
  margin-top: 7px;
  margin-bottom: 0.3rem;
}

.item .dlt {
  /* font-weight: bold;
    font-size: 125%; */
  margin-top: 7px;
  float: right;
  cursor: pointer;
  /* margin-bottom: 0.3rem; */
}

.item .lefticon {
  float: left;
  font-weight: bold;
  font-size: 175%;
  margin-top: 7px;
  margin-bottom: 0.3rem;
  margin-left: 10px;
}

.item .body {
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .small {
  font-size: 66%;
  text-align: right;
}

.item .small2 {
  text-align: right;
}

.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}
.toolbar {
  margin-left: 300px;
  margin-right: 60px;
}
.createBtn {
  float: left;
  color: #ffffff;
  text-decoration: none;
}
.headtext {
  font-size: 30px;
}
.createBtn2 {
  padding: 10px;
  color: white;
  background: #6c757d;
  border-radius: 5px;
}
.btnmargin {
  margin-bottom: 10px;
  margin-left: 20px;
}

.btnmargin2 {
  float: right;
}

.addsubmit {
  margin-top: 10px;
  padding: 10px 15px;
}
