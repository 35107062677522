.invoice {
  background-color: white;
  padding: 20px;
  /* border: 1px solid; */
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
  margin-top: -30px;
}

.invoice_header {
  display: flex;
  justify-content: space-between;
}
.invoice_headerbutton {
  display: flex;
}
.invoice_details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.invoice_detailleft {
  display: flex;
  padding: 5px;
  flex: 0.6;
}
.invoice_detailleftfirst {
  border: 1px solid black;
  margin: 5px;
  width: 300px;
  padding: 5px;
}
.invoice_detailleftsecond {
  border: 1px solid black;
  width: 300px;
  margin: 5px;
  padding: 5px;
}
.invoice_detailright {
  flex: 0.3;
}
.invoice_detailleftprovider {
  display: flex;
  justify-content: space-between;
}
.first_of_type {
  flex: 0.3;
}
.second_of_type {
  flex: 0.7;
}

.invoice_detailsbelow {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
}
.invoice_detailsbelowleft {
  display: flex;
  padding: 15px;
  flex-direction: column;
}
.invoice_detailsbelowleftup {
  display: flex;
  margin: 5px;
}
.invoice_detailsbelowright {
  padding: 15px;
}
.invoice_belowrightdetail {
  display: flex;
  margin: 5px;
}
.invoice_belowrightdetailfirst {
  flex: 0.4;
}
.invoice_detailinput {
  display: flex;
  flex: 0.6;
}

.invoice_detailsbelowleftbelow {
  /* position: relative;
  height: 81px;
  width: 250px;
  margin-top: 15px;
  border: 1px dotted;
  padding: 5px; */
  display: inline-block;
    padding: 10px;
    border: 1px dashed #333;
    border-radius: 6px;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin: 5px;
    height: 120px;
}

.head-style{
  font-size: 20px !important;
}
.netrevenue {
  position: absolute;
  right: 0;
}
.invoice_detailinput input {
  text-align: right;
}
