.dashboard {
  height: 60px !important;
}

.dummy_content {
  margin-top: 74px;
  transition: all 0.5s ease;

  /* display: flex; */
  padding: 5px;
}

@media only screen and (max-width: 600px) {
  .dummy_content {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .dummy_content {
    flex-direction: column;
  }
}

.dummy_contentleft {
  /* flex: 0.2; */
  background: white;
  margin: 5px;
  padding: 10px;
  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
}

.dummy_contentright {
  /* flex: 0.8; */
  /* background: white; */
  margin: 5px;
  transition: all 0.5s ease;

  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
}

.dummy_contentleftheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabpanel {
  /* background-color: white; */
  margin: 10px;
}

.icon-margin {
  margin-left: 5px;
  margin-right: 5px;
}

.dummy_contentleftheader>p {
  color: white;
  background: blue;
  padding: 5px;
  border-radius: 5px;
  /* border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%; */
}

.margin {
  margin-top: 10px;
}

.marginleft {
  margin: 10px;
}

.dummy_contentlefticons {
  display: flex;
  justify-content: space-between;
}

.dummy_contentlefticon {
  border: 1px solid;
  border-radius: 100%;
  padding: 2px;
}

.font {
  font-size: 0.9rem !important;
}

.bold {
  margin-bottom: 0 !important;
}

.dummy_leftbutton {
  display: flex;
  margin-bottom: 10px;
}

.dummy_contentrighttabs {
  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
  margin: 5px;
  background: white;
  display: flex;
}

.dummy_contentrightdetail {
  box-shadow: 1px 1px 3px 1px rgb(102, 99, 99);
  margin: 5px;
  background: white;
}

.MuiTab-wrapper {
  text-transform: capitalize;
  font-weight: bolder;
}

.MuiBadge-dot {
  z-index: 0 !important;
}

.content-wrapper .card.client-profile .minimize-profile span {
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  color: #b1b1ca;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.content-wrapper .card.client-details .maximize-profile span {
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  color: #b1b1ca;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.content-wrapper .card.client-profile .minimize-profile span:hover {
  color: #071a52;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
}

.content-wrapper .card.client-details .maximize-profile span:hover {
  color: #071a52;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}